<template>
  <div class="mx-14 mt-6">
    <LegOverviewDialog
      v-if="showLegDialog"
      :visible="showLegDialog"
      :leg-id="selectedLegId"
      @close="
        showLegDialog = false
        refresh()
      "
    >
    </LegOverviewDialog>
    <EventOverviewDialog
      v-if="showEventDialog"
      :visible="showEventDialog"
      :event-id="selectedEventId"
      :leg-id="selectedEventLegId"
      @close="
        showEventDialog = false;
        refresh()
      "
    >
    </EventOverviewDialog>
    <EventsFormDialog
      v-if="showEventFormDialog"
      :visible="showEventFormDialog"
      :leg-id="selectedEvent.leg ? selectedEvent.leg.id : null"
      :event-id="selectedEvent.id"
      :title="selectedEvent.label"
      @saved="
        showEventFormDialog = false;
        refresh();
      "
      @close="
        showEventFormDialog = false;
        refresh();
      "
    >
    </EventsFormDialog>
    <DeleteDialog
      v-if="showDeleteDialog"
      :visible="showDeleteDialog"
      @delete-confirmed="onDeleteConfirmed"
      @close="
        showDeleteDialog = false;
        notDeletedAlertText = '';
        refresh();
      "
      title="Delete Event?"
      :warning="notDeletedAlertText"
    >
    </DeleteDialog>
    <h4 class="display-1 mb-4">All Events</h4>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search in Name"
          label="Search"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col sm="4">
        <Autocomplete
          v-model="selectedDeviceId"
          label="Device"
          url="gears/short"
          item-text="fullname"
          item-value="id"
          :server-search=true
          search-field="search_string"
          :min-search-length=2
          hint="Type name to search"
        ></Autocomplete>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="Events"
      :options.sync="options"
      :server-items-length="totalEvents"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="totalEventsItemsPerPage"
      @click:row="showEvent"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-tooltip
            top
            v-if="
              editPermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                color="orange"
                class="mr-2"
                @click.stop="editEvent(item)"
              >
                {{$icons.mdiPencil}}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiPencil}}
          </v-icon>
          <v-tooltip
            top
            v-if="
              deletePermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-red mr-2"
                @click.stop="deleteEvent(item)"
              >
                {{$icons.mdiDeleteOutline}}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiDeleteOutline}}
          </v-icon>
        </v-row>
      </template>
      <template v-slot:[`item.lat_lon`]="{ item }">
        {{ item.latitude }}° {{ item.longitude }}°
      </template>
      <template v-slot:[`item.time`]="{ item }">
        <div v-if="item.date_flag">
          {{item.time}}
        </div>
        <div v-else>
          {{item.time.trim().split(/\s+/)[0].split('-')[0]}}
        </div>
      </template>
      <template v-slot:[`item.leg`]="{ item }">
        <div v-if="item.leg">
          <v-chip
            class="osis-chip leg-chip"
            @click.stop="showLeg(item.leg.id)"
          >
            {{ item.leg.name }}
          </v-chip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'TotalEvents',
  components: {
    EventsFormDialog: () => import('@/components/Events/EventFormDialog.vue'),
    DeleteDialog: () => import('@/components/DeleteDialog.vue'),
    LegOverviewDialog: () => import('@/components/Legs/LegOverviewDialog.vue'),
    EventOverviewDialog: () => import('@/components/Events/EventOverviewDialog.vue'),
    Autocomplete: () => import('@/components/Basics/Autocomplete.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      nameSearch: '',
      nameAwaitingSearch: false,
      totalEvents: 0,
      Events: [],
      abortController: null,
      loading: true,
      showDeleteDialog: false,
      showEventFormDialog: false,
      selectedLegId: null,
      showLegDialog: false,
      selectedEventId: null,
      showEventDialog: false,
      editPermission: false,
      deletePermission: false,
      selectedEvent: null,
      notDeletedAlertText: '',
      selectedDeviceId: null,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'label'
        },
        {
          text: 'Optional Label',
          value: 'name'
        },
        {
          text: 'Leg',
          value: 'leg',
          sortable: false
        },
        {
          text: 'Description',
          value: 'title'
        },
        {
          text: 'Device',
          value: 'gear',
          sortable: false,
        },
        {
          text: 'Lat Lon',
          value: 'lat_lon',
          sortable: false,
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      typeItems: [
        { text: 'DSHIP', value: "DSHIP" },
        { text: 'Blog', value: "Blog" },
        { text: 'Location', value: "Location" },
        { text: 'Sample Repository', value: "Sample Repository" },
        { text: 'Code Repository', value: "Code Repository" },
        { text: 'Cruise Report', value: "Cruise Report" },
        { text: 'Print Publication', value: "Print Publication" },
        { text: 'URL', value: "URL" },
        { text: 'Open Data Repository', value: "Open Data Repository" },
        { text: 'Internal Data Repository', value: "Internal Data Repository" }
      ],
      selectedTypes: [],
    }
  },
  watch: {
    nameSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    'options': {
      async handler() {
        await this.getDataFromApi()
        await this.getPermissions()
      }
    },
    'selectedDeviceId': {
      handler() {
        this.getDataFromApi()
      }
    },
  },
  async mounted() {
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.name = this.nameSearch }
      if (this.selectedDeviceId || this.selectedDeviceId === 0) { filter.gear_id = this.selectedDeviceId }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    totalEventsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("totalEventsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("totalEventsItemsPerPage", value);
      },
    },
  },
  methods: {
    async refresh() {
      await this.getDataFromApi()
      await this.getPermissions()
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getEvents()
        if (data) {
          this.Events = data.data
          this.totalEvents = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getEvents() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'events',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async getPermissions() {
      let permissionsToAsk = {
        'editPermission': { route: '/v1/expeditions/1/events/1', action: 'PATCH' },
        'deletePermission': { route: '/v1/expeditions/1/events/1', action: 'DELETE' },
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.editPermission = permissions.editPermission.permission
      this.deletePermission = permissions.deletePermission.permission
    },
    deleteEvent(link) {
      this.selectedEvent = link
      this.showDeleteDialog = true
    },
    async onDeleteConfirmed() {
      let url = 'expeditions/' + this.selectedEvent.leg.id + '/events/' + this.selectedEvent.id
      const requestParams = {
        method: "DELETE",
        url: url,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 204) {
          this.showDeleteDialog = false
          await this.refresh()
        }
        else {
          const data = await res.json()
          this.notDeletedAlertText = data.message
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    editEvent(event) {
      this.selectedEvent = event
      this.showEventFormDialog = true
    },
    showLeg(legId) {
      this.selectedLegId = legId
      this.showLegDialog = true
    },
    showEvent(event) {
      this.selectedEventId = event.id
      this.selectedEventLegId = event.leg.id
      this.showEventDialog = true
    },
  },
}
</script>

<style>
</style>