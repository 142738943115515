<template>
  <nav>
    <v-app-bar
      hide-on-scroll
      class="osis-title-bar"
      v-resize="onResize"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon color="white">{{mdiMenu}}</v-icon>
      </v-app-bar-nav-icon>
      <div @click="goHome">
        <v-img
          style="cursor: pointer"
          class="mx-4"
          height="40"
          width="40"
          max-width="40"
          :src="osisLogo"
        ></v-img>
      </div>

      <v-btn
        text
        @click="goHome"
      >
        <div
          class="text-h5"
          style="color: white"
        >
          <div v-if="!isSandbox">

            <div v-if="!isMobile">
              <strong>O</strong>cean <strong>S</strong>cience <strong>I</strong>nformation <strong>S</strong>ystem
            </div>
            <div v-else>
              OSIS
            </div>
          </div>
          <div v-else>
            OSIS Sandbox
          </div>
        </div>
      </v-btn>
      <v-spacer></v-spacer>
      <v-img
        v-if="!isMobile"
        class="mx-4"
        height=40px
        width=99px
        max-width=99px
        :src="require('/public/images/Geomar-Logo-weiss.svg')"
      ></v-img>
      <IssueButton v-if="this.$store.state.token"></IssueButton>

      <v-btn
        @click="goDoku"
        icon
      >
        <v-icon color="white"> {{mdiHelp}}</v-icon>
      </v-btn>
      <v-btn
        v-if="!this.$store.state.token"
        @click="showLogin"
        icon
      >
        <v-icon color="white"> {{mdiLoginVariant}}</v-icon>
      </v-btn>
      <div
        v-else
        style="display: flex; align-items: center;"
      >

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="white">{{mdiAccount}}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="goToMyOsis">
              <v-list-item-title>My Osis</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item @click="goToMyContexts">
              <v-list-item-title>My Contexts</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      absolute
      temporary
    >
      <v-list-item>
        <v-list-item-group>
          <v-list-item
            router
            :to="homeLink.route"
          >
            <v-list-item-title>{{ homeLink.text }}</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            v-for="link in firstLinks"
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
          <v-divider v-if="links.length>0"></v-divider>
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list-item>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import permission from '@/mixins/permissions'
import { mdiMenu, mdiLoginVariant, mdiAccount, mdiChevronDown, mdiHelp } from '@mdi/js'

export default {
  mixins: [permission],
  components: {
    IssueButton: () => import('@/components/Basics/IssueButton.vue'),
  },
  data() {
    return {
      isSandbox: false,
      mobileViewThreshold: 800,
      isMobile: false, // Track the screen size
      drawer: false,
      adminAccess: false,
      projectAccess: false,
      osisLogo: require('/public/images/osis_logo.svg'),
      homeLink: { text: 'Home', route: '/' },
      firstLinks: [
        { text: 'Expeditions', route: '/expeditions' },
        { text: 'Simulations', route: '/simulations' },
        { text: 'Experiments', route: '/experiments' }
      ],
      mdiMenu, mdiLoginVariant, mdiAccount, mdiChevronDown, mdiHelp
    }
  },
  computed: {
    links() {
      this.getPermissions()
      let links = []
      if (this.adminAccess) {
        links.push({ text: 'Files', route: '/files' })
        links.push({ text: 'Data Links', route: '/data_links' })
        links.push({ text: 'Deliverables', route: '/deliverables' })
      }
      if (this.adminAccess || this.affiliationAccess) {
        links.push({ text: 'Affiliations', route: '/affiliations' })
      }
      if (this.adminAccess) {
        links.push({ text: 'Devices', route: '/devices' })
        links.push({ text: 'Events', route: '/events' })
        links.push({ text: 'Locations', route: '/locations' })
        links.push({ text: 'Networks', route: '/event_networks' })
        links.push({ text: 'Persons', route: '/persons' })
        links.push({ text: 'Platforms', route: '/platforms' })
      }
      if (this.adminAccess || this.projectAccess) {
        links.push({ text: 'Projects', route: '/projects' })
      }
      if (this.adminAccess) {
        links.push({ text: 'Sponsors', route: '/sponsors' })
      }
      return links
    },
  },
  mounted() {
    let isSandbox
    isSandbox = process.env.VUE_APP_IS_SANDBOX
    this.isSandbox = (isSandbox === 'true')
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < this.mobileViewThreshold
    },
    showLogin() {
      this.$router.push({ name: 'Login' })
    },
    goHome() {
      if (this.$route.name != 'Home') {
        this.$router.push({ name: 'Home' })
      }
    },
    goDoku() {
      this.$router.push({ name: 'Docu' })
    },
    goToMyOsis() {
      if (this.$route.name != 'MyOsis') {
        this.$router.push({ name: 'MyOsis' })
      }
    },
    goToMyContexts() {
      if (this.$route.name != 'MyContexts') {
        this.$router.push({ name: 'MyContexts' })
      }
    },
    logout() {
      this.$store.dispatch('deleteToken')
      this.$store.dispatch('deleteUserName')
      if (this.$route.name != 'Home') {
        this.$router.push({ name: 'Home' })
      }
    },
    async getPermissions() {
      let permissionsToAsk = {
        'adminAccess': { permission_requested: 'admin_access' },
        'projectAccess': { permission_requested: 'project_access' },
        'affiliationAccess': { permission_requested: 'affiliation_access' },
      }
      let permissions = await this.getGeneralPermission(permissionsToAsk)
      this.adminAccess = permissions.adminAccess.permission
      this.projectAccess = permissions.projectAccess.permission
      this.affiliationAccess = permissions.affiliationAccess.permission
    },
    removeRoute(routeName) {
      const index = this.$router.options.routes.findIndex(route => route.name === routeName);
      if (index !== -1) {
        this.$router.options.routes.splice(index, 1);
      }
    }
  }
}
</script>

<style>
</style>
