<template>
  <div>
    <ErrorAlert
      v-if="deliverableDoesNotExist"
      @close="deliverableDoesNotExist = false"
    >
      {{ deliverableDoesNotExistText }}
    </ErrorAlert>
    <DeliverableFormDialog
      v-if="showDeliverableFormDialog"
      :visible="showDeliverableFormDialog"
      :leg-id="legId"
      :deliverable-id="selectedDeliverableId"
      @saved="
        showDeliverableFormDialog = false;
        refresh();
      "
      @close="
        showDeliverableFormDialog = false;
        refresh();
      "
    >
    </DeliverableFormDialog>
    <DeleteDialog
      v-if="showDeleteDialog"
      :visible="showDeleteDialog"
      @delete-confirmed="onDeleteConfirmed"
      @close="
        showDeleteDialog = false;
        notDeletedAlertText = '';
        refresh();
      "
      title="Delete Deliverable?"
      :warning="notDeletedAlertText"
    >
    </DeleteDialog>
    <DeliverableOverviewDialog
      v-if="showDeliverableOverviewDialog"
      :visible="showDeliverableOverviewDialog"
      :deliverable-id="selectedDeliverableId"
      :leg-id="legId"
      @close="
        showDeliverableOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </DeliverableOverviewDialog>
    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="deliverables"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, -1],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="deliverablesItemsPerPage"
      @click:row="showDeliverableDialog"
      :key="dataTableForceRefresh"
    >
      <template v-slot:top>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-btn
                v-if="postPermission"
                class="elevation-1 plus-button"
                fab
                x-small
                @click="addDeliverable()"
              >
                <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.flag`]="{ item }">
        <div :class="flagColours[item.id]">{{ item.flag }}</div>
      </template>
      <template v-slot:[`item.due`]="{ item }">
        <div v-if="item.flag!='PUBLISHED'">
          {{ item.due.trim().split(/\s+/)[0] }}
        </div>
      </template>
      <template v-slot:[`item.file`]="{ item }">
        <div v-if="item.file">
          {{ item.file.name }}
          <div v-if="item.file.description">({{ item.file.description }})</div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-tooltip
            top
            v-if="
              editPermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-orange mr-2"
                @click.stop="editDeliverable(item)"
              >
                {{$icons.mdiPencil}}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiPencil}}
          </v-icon>
          <v-tooltip
            top
            v-if="
              deletePermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-red mr-2"
                @click.stop="deleteDeliverable(item)"
              >
                {{$icons.mdiDeleteOutline}}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiDeleteOutline}}
          </v-icon>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiRequests from '@/mixins/apiRequests'
import permissions from '@/mixins/permissions'
import helperFunctions from '@/mixins/helperFunctions'

export default {
  name: 'Deliverables',
  mixins: [apiRequests, permissions, helperFunctions],
  props: ['legId'],
  components: {
    DeliverableOverviewDialog: () => import('@/components/Deliverables/DeliverableOverviewDialog.vue'),
    DeliverableFormDialog: () => import('@/components/Deliverables/DeliverableFormDialog.vue'),
    DeleteDialog: () => import('@/components/DeleteDialog.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  data() {
    return {
      deliverables: [],
      flagColours: {},
      dataTableForceRefresh: 0,
      loading: true,
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Device',
          value: 'gear',
        },
        {
          text: 'Parameter',
          value: 'parameter',
        },
        {
          text: 'resp. Person',
          value: 'pic',
        },
        {
          text: 'File',
          value: 'file',
        },
        {
          text: 'Flag',
          value: 'flag',
        },
        {
          text: 'Due Date',
          value: 'due',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      selectedDeliverableId: null,
      showDeliverableOverviewDialog: false,
      showDeliverableFormDialog: false,
      showDeleteDialog: false,
      notDeletedAlertText: '',
      editPermission: false,
      postPermission: false,
      deletePermission: false,
      gear: {
        id: null,
        loading: false,
        search: '',
        items: [],
        selection: []
      },
      mountedByRouter: false,
      deliverableDoesNotExist: false,
      deliverableDoesNotExistText: ""
    }
  },
  computed: {
    deliverablesItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("deliverablesItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("deliverablesItemsPerPage", value);
      },
    },
  },
  watch: {
    '$route.params.deliverable_id': {
      handler: function (deliverable_id) {
        if (deliverable_id) {
          this.selectedDeliverableId = deliverable_id
          this.showDeliverableOverviewDialog = true
        }
        else {
          this.selectedDeliverableId = null
          this.showDeliverableOverviewDialog = false
        }
      },
    }
  },
  beforeMount() {
    if (this.$route.name === "LegDeliverables") {
      this.mountedByRouter = true
    }
  },
  async mounted() {
    await this.getDeliverables()
    this.getFlagColours()
    this.dataTableForceRefresh += 1
    await this.getPermissions()
    if (this.$route.params.deliverable_id) {
      let deliverableExists = await this.doesDeliverableExist(this.$route.params.deliverable_id)
      if (deliverableExists) {
        this.selectedDeliverableId = this.$route.params.deliverable_id
        this.showDeliverableOverviewDialog = true
      }
      else {
        this.deliverableDoesNotExist = true
        this.deliverableDoesNotExistText = 'Deliverable ' + this.$route.params.deliverable_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  methods: {
    async getDeliverables() {
      this.loading = true
      const requestParams = {
        method: "GET",
        url: 'expeditions/' + this.legId + '/deliverables'
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        this.deliverables = await res.json()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    getFlagColours() {
      const dateNow = new Date()
      for (const element of this.deliverables) {
        const dateDue = new Date(element.due)
        if (element.flag === 'OPEN') {
          if (dateDue > dateNow) {
            this.flagColours[element.id] = 'black--text'
          } else {
            this.flagColours[element.id] = 'red--text'
          }
        }
        if (element.flag === 'OSIS') {
          if (dateDue > dateNow) {
            this.flagColours[element.id] = 'black--text'
          } else {
            this.flagColours[element.id] = 'orange--text'
          }
        }
        if (element.flag === 'PUBLISHED') {
          this.flagColours[element.id] = 'green--text'
        }
      }
    },
    async getPermissions() {
      let permissionsToAsk = {
        'patchPermission': { leg_id: this.legId, action: 'patch' }
      }
      let permissions = await this.getExpeditionPermissionFromApi(permissionsToAsk)
      this.editPermission = permissions.patchPermission.permission
      this.postPermission = permissions.patchPermission.permission
      this.deletePermission = permissions.patchPermission.permission
    },
    async refresh() {
      await this.getDeliverables()
      this.getFlagColours()
      this.dataTableForceRefresh += 1
      this.$emit('deliverables-refreshed')
    },
    showDeliverableDialog(deliverable) {
      if (this.mountedByRouter) {
        this.$router.push({ name: 'LegDeliverables', params: { leg_id: this.legId, deliverable_id: deliverable.id } })
      } else {
        this.selectedDeliverableId = deliverable.id
        this.showDeliverableOverviewDialog = true
      }
    },
    redirectHome() {
      if (this.mountedByRouter) {
        this.$router.push({ name: 'LegDeliverables', params: { leg_id: this.legId } })
      }
    },
    addDeliverable() {
      this.selectedDeliverableId = null
      this.showDeliverableFormDialog = true
    },
    editDeliverable(deliverable) {
      this.selectedDeliverableId = deliverable.id
      this.showDeliverableFormDialog = true
    },
    deleteDeliverable(deliverable) {
      this.idToDelete = deliverable.id
      this.showDeleteDialog = true
    },
    async onDeleteConfirmed() {
      let url = 'expeditions/' + this.legId + '/deliverables/' + this.idToDelete

      const requestParams = {
        method: "DELETE",
        url: url,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 204) {
          this.showDeleteDialog = false
          await this.refresh()
        }
        else {
          const data = await res.json()
          this.notSavedAlertText = this.extractFirstErrorMessage(data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    async doesDeliverableExist(deliverableId) {
      const requestParams = {
        method: "GET",
        url: 'expeditions/' + this.legId + '/deliverables/' + deliverableId,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
  }
}
</script>

<style>
</style>