<template>
  <div>
    <router-view></router-view>

    <v-card
      class="mx-auto mt-12"
      :max-width="caruselWidth"
      flat
    >
      <v-card
        class="mx-auto"
        :max-width="caruselWidth"
        flat
      >
        <div
          @mouseover="caruselCycle= false"
          @mouseleave="caruselCycle= true"
        >
          <v-carousel
            v-model="caruselSlide"
            :height=caruselHeigth
            hide-delimiter-background
            show-arrows-on-hover
            :cycle="caruselCycle"
            :interval=caruselCycleInterval
            :delimiter-icon="$icons.mdiCircle"
            :next-icon="$icons.mdiChevronRight"
            :prev-icon="$icons.mdiChevronLeft"
          >
            <v-carousel-item
              :reverse-transition="caruselTransition"
              :transition="caruselTransition"
            >
              <ShipsCurrentPositions
                :height=caruselHeigth
                v-if="caruselSlide == 0"
              >
              </ShipsCurrentPositions>
            </v-carousel-item>

            <v-carousel-item
              :reverse-transition="caruselTransition"
              :transition="caruselTransition"
            >
              <div class="video-container">
                <v-sheet
                  v-if=videoIsLoaded
                  :height="caruselHeigth"
                  width="100%"
                  color="black"
                ></v-sheet>
                <div class="video-container">
                  <video
                    :height="caruselHeigth"
                    muted
                    autoplay
                    loop
                    ref="globeVideo"
                    @loadeddata="videoLoaded"
                  >
                    <source
                      :src="require('/public/images/FOCI_SSH_SST_ICE_WIND_CCOVER_BLACK_fps9.mp4')"
                      type="video/mp4"
                    >

                  </video>
                  <div
                    v-if=videoIsLoaded
                    class="overlay1"
                  ></div>
                  <div
                    v-if=videoIsLoaded
                    class="overlay2"
                  ></div>
                </div>

                <v-sheet
                  v-if="videoIsLoaded"
                  :height="caruselHeigth"
                  width="5000px"
                  color="black"
                >
                  <v-card-text class="image-text-container">
                    <span class="link-text">
                      <a
                        style="color: white !important; text-decoration: none;"
                        href='https://doi.org/10.3289/FOCI_SST_ICE_WIND_CCOVER'
                        target="_blank"
                      >
                        Schulzki et al 2023
                      </a>
                    </span>
                  </v-card-text>
                </v-sheet>

              </div>
            </v-carousel-item>

            <v-carousel-item
              :reverse-transition="caruselTransition"
              :transition="caruselTransition"
            >
              <v-img
                :height=caruselHeigth
                :src="require('/public/images/2020-02-19_CUSCO_KOSMOS2020_0003_Ulf_Riebesell-GEOMAR_kleiner.jpg')"
              >
                <v-card-text class="image-text-container">
                  <div class="image-text">Ulf Riebesell, GEOMAR</div>
                </v-card-text>
              </v-img>
            </v-carousel-item>

          </v-carousel>
        </div>

      </v-card>
      <v-row>
        <v-col sm="4">
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover || caruselSlide == 0 ? 8 : 2"
              :class="{ 'on-hover': hover }"
              @mouseover="
                caruselSlide = 0;
                caruselCycle= false
              "
              @mouseleave="caruselCycle= true"
              class="mx-auto osis-card"
            >
              <v-card-text>
                <v-btn
                  class="osis-text-button"
                  text
                  @click="$router.push({ name: 'Legs'})"
                >
                  <v-icon
                    class="mr-4"
                    color=black
                    size="25"
                  >{{$icons.mdiFerry}}</v-icon>
                  <div class="text-h5">
                    Expeditions
                  </div>
                </v-btn>
                <v-row>
                  <v-col sm="10">
                    <v-text-field
                      v-model="LegNameSearch"
                      hint="Search Name"
                      label="Name"
                      persistent-hint
                      v-on:keyup.enter="searchLegs"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="2">
                    <v-btn
                      icon
                      @click="searchLegs"
                    ><v-icon>{{$icons.mdiMagnify}}</v-icon></v-btn>
                  </v-col>
                </v-row>
                <LatestLegs />
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>

        <v-col sm="4">
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover || caruselSlide == 1 ? 8 : 2"
              :class="{ 'on-hover': hover }"
              @mouseover="
                caruselSlide = 1;
                caruselCycle= false
              "
              @mouseleave="caruselCycle= true"
              class="mx-auto osis-card"
            >
              <v-card-text class="text-h5">
                <v-btn
                  class="osis-text-button"
                  text
                  @click="$router.push({ name: 'Simulations'})"
                >
                  <v-icon
                    class="mr-4"
                    color=black
                    size="25"
                  >{{$icons.mdiEarth}}</v-icon>
                  <div class="text-h5">
                    Simulations
                  </div>
                </v-btn>
                <v-row>
                  <v-col sm="10">
                    <v-text-field
                      v-model="SimulationNameSearch"
                      hint="Search Name"
                      label="Name"
                      persistent-hint
                      v-on:keyup.enter="searchSimulations"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="2">
                    <v-btn
                      icon
                      @click="searchSimulations"
                    ><v-icon>{{$icons.mdiMagnify}}</v-icon></v-btn>
                  </v-col>
                </v-row>
                <LatestSimulations />
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>

        <v-col sm="4">

          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover || caruselSlide == 2 ? 8 : 2"
              :class="{ 'on-hover': hover }"
              @mouseover="
                caruselSlide = 2;
                caruselCycle= false
              "
              @mouseleave="caruselCycle= true"
              class="mx-auto osis-card"
            >
              <v-card-text class="text-h5">
                <v-btn
                  class="osis-text-button"
                  text
                  @click="$router.push({ name: 'Experiments'})"
                >
                  <v-icon
                    class="mr-4"
                    color=black
                    size="25"
                  >{{$icons.mdiTestTube}}</v-icon>
                  <div class="text-h5">
                    Experiments
                  </div>
                </v-btn>
                <v-row>
                  <v-col sm="10">
                    <v-text-field
                      v-model="ExperimentLabelSearch"
                      hint="Search Label"
                      label="Label"
                      persistent-hint
                      v-on:keyup.enter="searchExperiments"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="2">
                    <v-btn
                      icon
                      @click="searchExperiments"
                    ><v-icon>{{$icons.mdiMagnify}}</v-icon></v-btn>
                  </v-col>
                </v-row>
                <LatestExperiments />
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    LatestLegs: () => import('@/components/Legs/LatestLegs.vue'),
    LatestSimulations: () => import('@/components/Simulations/LatestSimulations.vue'),
    LatestExperiments: () => import('@/components/Experiments/LatestExperiments.vue'),
    ShipsCurrentPositions: () => import('@/components/Maps/ShipsCurrentPositions.vue')
  },

  data() {
    return {
      caruselSlide: null,
      caruselCycle: true,
      caruselCycleInterval: 60000,
      caruselTransition: "scroll-transition",
      caruselHeigth: 350,
      caruselWidth: 1200,
      LegNameSearch: '',
      SimulationNameSearch: '',
      ExperimentLabelSearch: '',
      videoIsLoaded: false,
      playbackSpeed: 0.90
    }
  },
  computed: {
  },
  beforeMount() {
    let isSandbox
    isSandbox = process.env.VUE_APP_IS_SANDBOX
    if (isSandbox) { this.$router.push({ name: 'Login' }) }
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    searchLegs() {
      this.$router.push({ name: 'Legs', params: { nameSearch: this.LegNameSearch } })
    },
    searchSimulations() {
      this.$router.push({ name: 'Simulations', params: { nameSearch: this.SimulationNameSearch } })
    },
    searchExperiments() {
      this.$router.push({ name: 'Experiments', params: { labelSearch: this.ExperimentLabelSearch } })
    },
    videoLoaded() {
      // Handle the event when the video is loaded
      this.videoIsLoaded = true
      this.$refs.globeVideo.playbackRate = this.playbackSpeed;
    },
  }
}
</script>

<style>
.video-container {
  position: relative;
}

.overlay1 {
  position: absolute;
  bottom: 0;
  right: 1%; /* Subtract 20 pixels from the right position */
  width: 25%; /* Adjust the width to cover the desired portion */
  height: 90%; /* Adjust the height to cover the desired portion */
  background-color: black; /* You can set the desired color or use transparent */
  opacity: 1; /* Adjust the opacity to your preference */
}
.overlay2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 39%; /* Adjust the width to cover the desired portion */
  height: 6.5%; /* Adjust the height to cover the desired portion */
  background-color: black; /* You can set the desired color or use transparent */
  opacity: 1; /* Adjust the opacity to your preference */
}

.white-link {
  color: white !important;
  text-decoration: none;
}
</style>