<template>
  <div class="mx-14 mt-6">
    <ErrorAlert
      v-if="legDoesNotExist"
      @close="legDoesNotExist = false"
    >
      {{ legDoesNotExistText }}
    </ErrorAlert>
    <LegFormDialog
      v-if="showLegFormDialog"
      :visible="showLegFormDialog"
      title="New Expedition"
      @saved="
        showLegFormDialog = false;
        onLegSave($event);
      "
      @close="
        showLegFormDialog = false;
        refresh();
      "
    >
    </LegFormDialog>
    <LegOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :leg-id="selectedLegId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </LegOverviewDialog>
    <h4 class="display-1 mb-4">Expeditions</h4>
    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-model="selectedPlatformId"
          label="Platform"
          url="platforms/short"
          item-text="fullname"
          item-value="id"
          :server-search=true
          :min-search-length=2
          hint="Type name to search"
          @input="filterInput"
        ></Autocomplete>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-model="selectedContextId"
          label="Community Context"
          url="contexts"
          item-text="name"
          item-value="id"
          @input="contextInput"
          :key=autocompleteContextKey
        ></Autocomplete>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-if="getPersonPermission"
          v-model="selectedPiId"
          label="Person"
          url="persons/short"
          item-text="fullname"
          item-value="id"
          :server-search=true
          :min-search-length=2
          hint="Type name to search"
          @input="filterInput"
        ></Autocomplete>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postLegPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addLeg"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="expeditions"
      :options.sync="options"
      :server-items-length="totalLegs"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="legsItemsPerPage"
      @click:row="showLegDialog"
    >
      <template v-slot:[`item.related_projects`]="{ item }">
        <div>
          <span
            v-for="(name, index) in item.projects"
            :key="index"
          >
            <span>{{ name.name }}</span><span v-if="index + 1 < item.projects.length">, </span>
          </span>
        </div>
      </template>
      <template v-slot:[`item.departure_date`]="{ item }">
        {{ item.departure_port.date }} {{ item.departure_port.location }}
      </template>
      <template v-slot:[`item.return_date`]="{ item }">
        {{ item.return_port.date }} {{ item.return_port.location }}
      </template>
      <template v-slot:[`item.web_visibility`]="{ item }">
        <v-icon v-if="item.web_visibility">
          {{$icons.mdiCheck}}
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'Legs',
  components: {
    LegFormDialog: () => import('@/components/Legs/LegFormDialog.vue'),
    LegOverviewDialog: () => import('@/components/Legs/LegOverviewDialog.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
    Autocomplete: () => import('@/components/Basics/Autocomplete.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postLegPermission: false,
      getPersonPermission: false,
      nameSearch: '',
      nameAwaitingSearch: false,
      selectedPlatformId: null,
      selectedPiId: null,
      selectedLegId: null,
      selectedCruiseId: null,
      selectedContextId: null,
      autocompleteContextKey: 0,
      totalLegs: 0,
      expeditions: [],
      abortController: null,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Projects',
          value: 'related_projects',
          sortable: false,
        },
        {
          text: 'Chief Scientist',
          value: 'chief_scientist',
          sortable: false,
        },
        {
          text: 'Departure',
          value: 'departure_date',
        },
        {
          text: 'Return',
          value: 'return_date',
        },
        {
          text: 'Events',
          value: 'event_count',
          sortable: false,
        },
        {
          text: 'Files',
          value: 'related_file_count',
          sortable: false,
        },
        {
          text: 'Links',
          value: 'related_data_link_count',
          sortable: false,
        },
        {
          text: 'Web Visibility',
          value: 'web_visibility',
          align: 'center',
          sortable: false,
        }
      ],
      showLegFormDialog: false,
      showOverviewDialog: false,
      legDoesNotExist: false,
      legDoesNotExistText: "",
      mdiArrowLeft
    }
  },
  watch: {
    '$route.params.leg_id': {
      handler: function (leg_id) {
        if (leg_id) {
          this.selectedLegId = parseInt(leg_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLegId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    }
  },
  async mounted() {
    if (this.$route.params.nameSearch) {
      this.nameSearch = this.$route.params.nameSearch
    }
    if (this.$route.params.contextId) {
      this.$router.replace({ query: { contextId: this.$route.params.contextId } })
    }
    if (this.$route.query.contextId) {
      this.selectedContextId = parseInt(this.$route.query.contextId)
      this.autocompleteContextKey += 1
    }
    if (this.$route.query.cruiseId) {
      this.selectedCruiseId = parseInt(this.$route.query.cruiseId)
      this.autocompleteCruiseKey += 1
    }
    await this.getPermissions()
    if (this.$route.params.leg_id) {
      let legExists = await this.doesLegExist(this.$route.params.leg_id)
      if (legExists) {
        this.selectedLegId = this.$route.params.leg_id
        this.showOverviewDialog = true
      }
      else {
        this.legDoesNotExist = true
        this.legDoesNotExistText = 'Expedition ' + this.$route.params.leg_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.name = this.nameSearch }
      if (this.selectedPiId || this.selectedPiId === 0) { filter.chief_scientist_id = this.selectedPiId }
      if (this.selectedPlatformId || this.selectedPlatformId === 0) { filter.platform_id = this.selectedPlatformId }
      if (this.selectedCruiseId || this.selectedCruiseId === 0) { filter.cruise_id = this.selectedCruiseId }
      if (this.selectedContextId) { filter.contexts = [{ 'id': this.selectedContextId }] }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    legsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("legsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("legsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postLegPermission': { route: '/v1/expeditions', action: 'POST' },
        'getPersonPermission': { route: '/v1/persons/short', action: 'GET' }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postLegPermission = permissions.postLegPermission.permission
      this.getPersonPermission = permissions.getPersonPermission.permission
    },
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'Legs' })
    },
    onLegSave(leg) {
      this.refresh()
      this.showLegDialog(leg)
    },
    showLegDialog(leg) {
      this.$router.push({ name: 'Legs', params: { leg_id: leg.id } })
    },
    addLeg() {
      this.selectedLegId = null
      this.showLegFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getLegs()
        if (data) {
          this.expeditions = data.data
          this.totalLegs = data.meta.total
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async getLegs() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'expeditions',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesLegExist(id) {
      const requestParams = {
        method: "GET",
        url: 'expeditions/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    contextInput() {
      this.$router.replace({ query: { contextId: this.selectedContextId } });
      this.filterInput()
    },
    filterInput() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>

<style>
</style>