var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-14 mt-6"},[(_vm.showLegDialog)?_c('LegOverviewDialog',{attrs:{"visible":_vm.showLegDialog,"leg-id":_vm.selectedLegId},on:{"close":function($event){_vm.showLegDialog = false
      _vm.refresh()}}}):_vm._e(),(_vm.showEventDialog)?_c('EventOverviewDialog',{attrs:{"visible":_vm.showEventDialog,"event-id":_vm.selectedEventId,"leg-id":_vm.selectedEventLegId},on:{"close":function($event){_vm.showEventDialog = false;
      _vm.refresh()}}}):_vm._e(),(_vm.showEventFormDialog)?_c('EventsFormDialog',{attrs:{"visible":_vm.showEventFormDialog,"leg-id":_vm.selectedEvent.leg ? _vm.selectedEvent.leg.id : null,"event-id":_vm.selectedEvent.id,"title":_vm.selectedEvent.label},on:{"saved":function($event){_vm.showEventFormDialog = false;
      _vm.refresh();},"close":function($event){_vm.showEventFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showDeleteDialog)?_c('DeleteDialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Delete Event?","warning":_vm.notDeletedAlertText},on:{"delete-confirmed":_vm.onDeleteConfirmed,"close":function($event){_vm.showDeleteDialog = false;
      _vm.notDeletedAlertText = '';
      _vm.refresh();}}}):_vm._e(),_c('h4',{staticClass:"display-1 mb-4"},[_vm._v("All Events")]),_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search in Name","label":"Search","persistent-hint":""},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-col',{attrs:{"sm":"4"}},[_c('Autocomplete',{attrs:{"label":"Device","url":"gears/short","item-text":"fullname","item-value":"id","server-search":true,"search-field":"search_string","min-search-length":2,"hint":"Type name to search"},model:{value:(_vm.selectedDeviceId),callback:function ($$v) {_vm.selectedDeviceId=$$v},expression:"selectedDeviceId"}})],1)],1),_c('v-data-table',{staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.Events,"options":_vm.options,"server-items-length":_vm.totalEvents,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.totalEventsItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.totalEventsItemsPerPage=$event},"update:items-per-page":function($event){_vm.totalEventsItemsPerPage=$event},"click:row":_vm.showEvent},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-row',[(
            _vm.editPermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.editEvent(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")]),(
            _vm.deletePermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"osis-red mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteEvent(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])],1)]}},{key:`item.lat_lon`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.latitude)+"° "+_vm._s(item.longitude)+"° ")]}},{key:`item.time`,fn:function({ item }){return [(item.date_flag)?_c('div',[_vm._v(" "+_vm._s(item.time)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.time.trim().split(/\s+/)[0].split('-')[0])+" ")])]}},{key:`item.leg`,fn:function({ item }){return [(item.leg)?_c('div',[_c('v-chip',{staticClass:"osis-chip leg-chip",on:{"click":function($event){$event.stopPropagation();return _vm.showLeg(item.leg.id)}}},[_vm._v(" "+_vm._s(item.leg.name)+" ")])],1):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }