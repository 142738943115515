<template>
  <v-dialog
    v-model="show"
    width="1000"
    persistent
  >
    <v-card>
      <v-card-title class="osis-title-bar">
        <div style="color: white">
          Imprint for <strong>O</strong>cean <strong>S</strong>cience <strong>I</strong>nformation <strong>S</strong>ystem (OSIS)
        </div>

        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="white"
          icon
          @click="cancelImprint"
        >
          <v-icon> {{$icons.mdiClose}} </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-card>
          <v-card-text style="text-align: justify;">

            <div class="text-h5">
              Published by
            </div>
            <p>GEOMAR Helmholtz Centre for Ocean Research<br />
              24148 Kiel<br />
              GERMANY<br />
              Tel. +49 (0)431 600-2294<br />
              Email: datamanagement@geomar.de
            </p>

            <div class="text-h5">
              Members of staff responsible
            </div>
            <p>Andreas Lehmann - Head of Digital Research Services at GEOMAR<br>
              Hela Mehrtens - Digital Research Services Data Manager at GEOMAR<br>
              Carsten Schirnick - Digital Research Services Data Manager at GEOMAR
            </p>

            <div class="text-h5">
              Hosting
            </div>
            <p>GEOMAR Helmholtz Centre for Ocean Research Kiel (Germany)</p>

            <div class="text-h5">
              Legal information
            </div>
            <p>All information on this website has been carefully checked. We make every effort to ensure that the information provided is up-to-date, correct and complete. Nevertheless, the occurrence of errors, especially in the scientifically collected research data and related metadata and contextual relations or links, can not be completely excluded. A guarantee for the completeness, correctness and last topicality can therefore not be assumed.</p>

            <div class="text-h5">
              Content from other providers
            </div>
            <p>Links to third-party content from OSIS (“third-party content”) were created by the GEOMAR data managers and legitimate researchers or research supporters (OSIS editors) to the best of their knowledge and with the greatest possible care and merely provide access to “third-party content” on other third-party websites. Particular attention was paid to the trustworthiness of third party providers and the accuracy and legality of the “third-party content” when creating the links.<br />
              Since the content of websites is very dynamic and can be changed at any time, particularly in the context of ongoing research, it is not always possible to check all content to which a link has been created on a case-by-case basis. The above mentioned editors do not expressly endorse the content of third party websites linked to this website. For any damages or errata in scientific research resulting from the use or non-use of “third-party content”, the respective provider of the linked page is solely liable.</p>

            <div class="text-h5">
              Limitation of liability
            </div>
            <p>The following limitation of liability applies to the information provided on this OSIS website: GEOMAR and its legitimate OSIS editors shall not be liable for any damage caused by the use or non-use of the information, the research data and scientific results provided here. GEOMAR and its legitimate OSIS editors may not be held liable for any damage caused by computer viruses, the installation or use of software, or the research data itself when accessing, downloading or using web content, research data or scientifc results.</p>

            <div class="text-h5">
              Notification of errors
            </div>
            <p>GEOMAR and its legitimate OSIS editors and its editorial team would be grateful upon notification of incorrect information or illegal content provided by other third-party websites to which access was provided thru links on our OSIS or related GEOMAR data management related websites. We would also appreciate information or notification about any content on our own OSIS website which is erroneous, incomplete, unintelligible or incompletely or incorrectly cited.</p>

            <div class="text-h5">
              Copyright
            </div>
            <p>The copyright for the texts, maps and data visualizations is held by GEOMAR and OSIS editors, unless otherwise noted by references to appropriate sources. The copyright of the logos of the participating institutions is held by these institutions, unless otherwise stated.<br />
              The text, maps, tabular data, graphs, data visualizations or links provided on this OSIS website may not be displayed, reproduced or redistributed without the prior permission of GEOMAR or OSIS editors or a proper citation in accordance with good scientific practice.</p>

          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImprintDialog',
  data() {
    return {
      show: true,
    }
  },
  watch: {
    show: function (newValue) {
      if (!newValue) {
        this.cancelImprint()
      }
    }
  },
  methods: {
    cancelImprint() {
      this.$router.go(-1)
    },
  }
}
</script>

<style>
</style>
