var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-14 mt-6"},[(_vm.successAlert)?_c('SuccessAlert',{on:{"close":function($event){_vm.successAlert = false}}},[_vm._v(" Email reminder sent ")]):_vm._e(),(_vm.showDeliverableFormDialog)?_c('DeliverableFormDialog',{attrs:{"visible":_vm.showDeliverableFormDialog,"leg-id":_vm.selectedLegId,"deliverable-id":_vm.selectedDeliverableId},on:{"saved":function($event){_vm.showDeliverableFormDialog = false;
      _vm.refresh();},"close":function($event){_vm.showDeliverableFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showDeleteDialog)?_c('DeleteDialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Delete Deliverable?","warning":_vm.notDeletedAlertText},on:{"delete-confirmed":_vm.onDeleteConfirmed,"close":function($event){_vm.showDeleteDialog = false;
      _vm.notDeletedAlertText = '';
      _vm.refresh();}}}):_vm._e(),(_vm.showLegDialog)?_c('LegOverviewDialog',{ref:"legOverviewDialog",attrs:{"visible":_vm.showLegDialog,"leg-id":_vm.selectedLegId,"preferred-tab":4},on:{"close":function($event){_vm.showLegDialog = false
      _vm.refresh()}}}):_vm._e(),(_vm.showDeliverableDialog)?_c('DeliverableOverviewDialog',{attrs:{"visible":_vm.showDeliverableDialog,"deliverable-id":_vm.selectedDeliverableId,"leg-id":_vm.selectedDeliverableLegId},on:{"close":function($event){_vm.showDeliverableDialog = false;
      _vm.refresh();}}}):_vm._e(),_c('h4',{staticClass:"display-1 mb-4"},[_vm._v("All Deliverables")]),_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Name","label":"Name","persistent-hint":""},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-col',{attrs:{"sm":"2"}},[(_vm.getPersonPermission)?_c('Autocomplete',{attrs:{"label":"Responsible Person","url":"persons/short","item-text":"fullname","item-value":"id","server-search":true,"min-search-length":2,"hint":"Type name to search"},on:{"input":_vm.filterInput},model:{value:(_vm.selectedPersonId),callback:function ($$v) {_vm.selectedPersonId=$$v},expression:"selectedPersonId"}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"sm":"2"}},[_c('v-btn',{staticClass:"osis-button",on:{"click":_vm.sendEmailReminder}},[_vm._v(" Send email reminder "),_c('v-icon',{staticClass:"osis-blue",attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.$icons.mdiEmailOutline)+" ")])],1)],1)],1),_c('v-data-table',{key:_vm.dataTableForceRefresh,staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.deliverables,"options":_vm.options,"server-items-length":_vm.totalDeliverables,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100, -1],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.totalDeliverablesItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.totalDeliverablesItemsPerPage=$event},"update:items-per-page":function($event){_vm.totalDeliverablesItemsPerPage=$event},"click:row":_vm.showDeliverable,"update:sort-by":_vm.sortTable,"update:sort-desc":_vm.sortTable},scopedSlots:_vm._u([{key:`item.leg`,fn:function({ item }){return [(item.leg)?_c('div',[_c('v-chip',{staticClass:"osis-chip leg-chip",on:{"click":function($event){$event.stopPropagation();return _vm.showLeg(item.leg.id)}}},[_vm._v(" "+_vm._s(item.leg.name)+" ")])],1):_vm._e()]}},{key:`item.flag`,fn:function({ item }){return [_c('div',{class:_vm.flagColours[item.id]},[_vm._v(_vm._s(item.flag))])]}},{key:`item.due`,fn:function({ item }){return [(item.flag!='PUBLISHED')?_c('div',[_vm._v(" "+_vm._s(item.due.trim().split(/\s+/)[0])+" ")]):_vm._e()]}},{key:`item.file`,fn:function({ item }){return [(item.file)?_c('div',[_vm._v(" "+_vm._s(item.file.name)+" "),(item.file.description)?_c('div',[_vm._v("("+_vm._s(item.file.description)+")")]):_vm._e()]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-row',[(
            _vm.editPermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"osis-orange mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editDeliverable(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")]),(
            _vm.deletePermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"osis-red mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDeliverable(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }