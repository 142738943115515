export default {
	methods: {
		async getRoutePermission(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/route_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
		async getAttributePermission(route, action, attribute) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/attribute_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						"route": route,
						"action": action,
						"attribute": attribute,
					})
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permission
			} catch (error) {
				console.log(error)
			}
		},
		async getFilePermissionFromApi(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/file_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
		async getPortCallPermissionFromApi(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/port_call_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
		async getExpeditionPermissionFromApi(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/expedition_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
		async getwebInfoExpeditionPermissionFromApi(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/web_info_expedition_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
		async getSimulationPermissionFromApi(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/simulation_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
		async getExperimentPermissionFromApi(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/experiment_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
		async getGeneralPermission(permissions) {
			try {
				let url = process.env.VUE_APP_API_URL
				const request = new Request(
					url + '/v1/general_permissions', {
					method: "PUT",
					headers: {
						'x-access-token': this.$store.state.token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ permissions })
				})

				const res = await fetch(request)
				const data = await res.json()
				return data.permissions
			} catch (error) {
				console.log(error)
			}
		},
	}
}