<template>
  <v-dialog
    v-model="show"
    width="1000"
    persistent
  >
    <v-card>
      <v-card-title class="osis-title-bar">
        <div style="color: white">
          Privacy Policy for <strong>O</strong>cean <strong>S</strong>cience <strong>I</strong>nformation <strong>S</strong>ystem (OSIS)
        </div>

        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="white"
          icon
          @click="cancelPrivacyPolicy"
        >
          <v-icon> {{$icons.mdiClose}} </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-card class="osis-card">
          <v-card-text>
            <div style="text-align: justify;">
              <div class="text-h5">
                Published by
              </div>
              <p>The GEOMAR Helmholtz Centre for Ocean Research Kiel takes the protection and handling of personal data seriously and provides the following information on what data is recorded or collected, when and for what purpose and on what legal basis, how you can contact the controller or the person responsible for data protection and what rights you have with regard to the processing of personal data.
                Personal data means any information that relates to or can be directly or indirectly related to an identified or an identifiable natural person.
                A natural person is identifiable in particular by the specification or assignment of a name, an identification number, location data, online identifiers or characteristics that express a physical, physiological, genetic, psychological, economic, cultural, intellectual or social identity of this natural person and enable identification (Article 4 (1) of the [EU General Data Protection Regulation (GDPR)](https://eur-lex.europa.eu/legal-content/EN/TXT/)).
              </p>
              <div class="text-h5">
                Responsible person and data protection officer
              </div>

              <div class="text-h6">
                Responsible for the processing of personal data in relation to <br><strong>O</strong>cean <strong>S</strong>cience <strong>I</strong>nformation <strong>S</strong>ystem (OSIS) at GEOMAR
              </div>
              <p>
                <strong>GEOMAR Helmholtz Centre for Ocean Research</strong> <br>
                Carsten Schirnick <br>
                Andreas Lehmann <br>
                24148 Kiel <br>
                GERMANY <br>
                Phone: +49 (0)431 600-2294 <br>
                Email: datamanagement@geomar.de <br>
              </p>
              <p>
                If you have any specific questions about the protection and handling of your data in relation to this website, please contact the GEOMAR data protection officer reachable under: <br>
                Stefan Simon <br>
                Phone: +49 (0)431 600-2549 <br>
                Email: datenschutz(at)geomar.de
              </p>

              <div class="text-h5">
                Data processing related to visiting this website
              </div>
              <p>
                Each time a user accesses or retrieves a file or data from this OSIS website, data on this activity is temporarily stored and processed in a log file. <br>
              </p>
              <p>
                Specifically, the following data is stored each time content is accessed: <br>
              <ul>
                <li>date and time of the access (time stamp), IP address of the device, proxy or server which is requesting access</li>
                <li>details of the inquiry and destination address (protocol version, HTTP method, referrer, user agent string)</li>
                <li>name of the file accessed or downloaded and amount of data transferred (requested URL including query string, volume in bytes)</li>
                <li>the code on whether access and information transfer was successful (HTTP status code)</li>
              </ul>
              </p>

              <p>
                On the basis of Article 6 (1) and (3) of the GDPR in conjunction with Section 5 of the Act on the Federal Office for Information Security, GEOMAR as hosting data center stores the above personal data in order to provide basic information for the purpose of detection of and protection against attacks on the internet infrastructure of GEOMAR beyond just the point in time of your visit. Data is analysed and, in the event of an attack on our communications technology, essentially needed to initiate further legal and criminal proceedings. The data is deleted as soon as it is no longer needed for these purposes.
              </p>
              <p>
                Data logged when you use OSIS website hosted at GEOMAR is only shared with third parties if we are obliged to do so by law or if it is necessary in the case of attacks on our communications technology for the purpose of illegal or criminal proceedings. Data is not shared under any other circumstances or joined with other data sources or information.
              </p>

              <div class="text-h5">
                Data collected when you contact GEOMAR data management per email
              </div>
              <p> When contacting the GEOMAR data management, any personal and technical data, at least the email address which you provide by means of email are processed and stored for the purpose of contacting you or replying to your request on the basis of Article 6 (1) of the GDPR.
                Contact per email is unencrypted and thus completely unprotected during the transmission process regarding any personal data in your email.
              </p>
              <p> In case you are using email addresses of third parties provided by the OSOS website, the processing of your personal data is not in the responsibility of GEOMAR or the data management. Please contact the addressee directly for inquiries regarding their handling of personal data.
              </p>

              <div class="text-h5">
                Data collected when you log on and create or upload, modify, or delete any content or information or research activity and research data
              </div>
              <p> When authenticating as legitimate user of OSIS your personal data (i.e. fullname and email address as used for authentication during log on) is processed and stored alongside with a timestamp for any of the actions you perform and finalize in order to create, upload, modify or delete any content or information related to research activity or research data in the narrow sense. Processing and preservation of these data is considered being of public interest and is archived solely for the purpose and in the context of scientific research and credability according to and in compliance with Article 5 (1) (b) and (e) of the GDPR.
              </p>

              <div class="text-h5">
                Your rights
              </div>
              <p>
                Regarding the processing of your personal data related to visiting this OSIS website you have in particular the following rights with respect to the data protection officer:

              </p>
              <p>
              <ul>
                <li>
                  <strong>Right of access</strong> - Article 15 of the GDPR:
                  <p>
                    This right gives you comprehensive access to data and information whether data concerning you and other related categories is being processed at all, such as the purpose of processing or the duration of storage.
                  </p>
                </li>
                <li>
                  <strong>Right to rectification</strong> - Article 16 of the GDPR:
                  <p>
                    This right enables you to have inaccurate personal data concerning you to be corrected without undue delay.
                  </p>
                </li>
                <li>
                  <strong>Right to erasure ('right to be forgotten') </strong> - Article 17 of the GDPR:
                  <p>
                    This right enables you to have the controller delete personal data concerning you. However, such data may be deleted only if it is no longer needed, if it was processed unlawfully or if consent covering its processing has been withdrawn and is not in conflict with the public interest to archive personal data solely for the purpose of credability and in the context of scientific research according to and in compliance with Article 5 (1) (b) and (e) of the GDPR. </p>
                </li>
                <li>
                  <strong>Right to restriction of processing</strong> - Article 18 of the GDPR:
                  <p>
                    This right enables you to temporarily prevent further processing of personal data concerning you. Such a restriction occurs first and foremost while the accuracy of personal data is contested, processing is unlawful, or you are examining whether to claim other rights.

                  </p>
                </li>
                <li>
                  <strong>Right to data portability</strong> - Article 20 of the GDPR:
                  <p>
                    This right enables you to receive the personal data concerning you in a commonly used and machine-readable format from the controller and, if necessary, to transmit those data to another controller. According to Article 20 (3) this right does not apply if the data processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.
                  </p>
                </li>
                <li>
                  <strong>Right to object to collecting, processing and/or use </strong> - Article 21 of the GDPR:
                  <p>
                    This right enables you to object in a special situation to further processing of your personal data if such processing is justified by the performance of public tasks or by public or private interests.
                  </p>
                </li>
                <li>
                  <strong>Right to lodge a complaint</strong>- Article 21 of the GDPR:
                  <p>
                    According to Article 77 (1) of the GDPR, you have the right to lodge a complaint with a supervisory authority if you consider that the processing of personal data related to you infringes the GDPR. The first level supervisory authority responsible for GEOMAR as hosting site is the "Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein".
                  </p>
                </li>
              </ul>
              </p>
              <!--


              ## 


             + **Right to erasure ('right to be forgotten')** - Article 17 of the GDPR:
              This right enables you to have the controller delete personal data concerning you. However, such data may be deleted only if it is no longer needed, if it was processed unlawfully or if consent covering its processing has been withdrawn and is not in conflict with the public interest to archive personal data solely for the purpose of credability and in the context of scientific research according to and in compliance with Article 5 (1) (b) and (e) of the GDPR.
              + **Right to restriction of processing** - Article 18 of the GDPR:
              This right enables you to temporarily prevent further processing of personal data concerning you. Such a restriction occurs first and foremost while the accuracy of personal data is contested, processing is unlawful, or you are examining whether to claim other rights.
              + **Right to data portability** - Article 20 of the GDPR:
              This right enables you to receive the personal data concerning you in a commonly used and machine-readable format from the controller and, if necessary, to transmit those data to another controller. According to Article 20 (3) this right does not apply if the data processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.
              + **Right to object to collecting, processing and/or use -** Article 21 of the GDPR:
              This right enables you to object in a special situation to further processing of your personal data if such processing is justified by the performance of public tasks or by public or private interests.
              + **Right to lodge a complaint** - Article 21 of the GDPR:
              According to Article 77 (1) of the GDPR, you have the right to lodge a complaint with a supervisory authority if you consider that the processing of personal data related to you infringes the GDPR. The first level supervisory authority responsible for GEOMAR as hosting site is the "Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein".

              ### 

               -->
              <div class="text-h6">
                You can contact the supervisory authority at:
              </div>
              <p>
                Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein in Kiel <br>
                Phone: +49 431 988-12 00 <br>
                Fax: +49 431 988-12 23 <br>
                E-Mail: mail@datenschutzzentrum.de <br>
                Homepage: https://www.datenschutzzentrum.de <br>
              </p>

            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PrivacyPolicyDialog',
  data() {
    return {
      show: true,
    }
  },
  watch: {
    show: function (newValue) {
      if (!newValue) {
        this.cancelPrivacyPolicy()
      }
    }
  },
  methods: {
    cancelPrivacyPolicy() {
      this.$router.go(-1)
    },
  }
}
</script>

<style>
</style>
