<template>
  <div class="mx-14 mt-6">
    <ErrorAlert
      v-if="simulationDoesNotExist"
      @close="simulationDoesNotExist = false"
    >
      {{ simulationDoesNotExistText }}
    </ErrorAlert>
    <h4 class="display-1 mb-4">Simulations</h4>
    <SimulationFormDialog
      v-if="showSimulationFormDialog"
      :visible="showSimulationFormDialog"
      title="New Simulation"
      @saved="
        showSimulationFormDialog = false;
        onSimulationSave($event);
      "
      @close="
        showSimulationFormDialog = false;
        refresh();
      "
    >
    </SimulationFormDialog>
    <SimulationOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :simulation-id="selectedSimulationId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </SimulationOverviewDialog>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col sm="2">
        <v-text-field
          v-model="descriptionSearch"
          hint="Search Description"
          label="Description"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-model="selectedContextId"
          label="Community Context"
          url="contexts"
          item-text="name"
          item-value="id"
          @input="contextInput"
          :key=autocompleteContextKey
        ></Autocomplete>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-model="selectedScopeId"
          label="Scopes"
          url="simulation_scopes"
          item-text="name"
          item-value="id"
          @input="filterInput"
        ></Autocomplete>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-model="selectedEsComponentId"
          label="ES Components"
          url="simulation_es_components"
          item-text="name"
          item-value="id"
          @input="filterInput"
        ></Autocomplete>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addSimulation"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="simulations"
      :options.sync="options"
      :server-items-length="totalSimulations"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="simulationsItemsPerPage"
      @click:row="showSimulationDialog"
      :key="tableComponentKey"
    >
      <template v-slot:[`item.name`]="{ item }">
        <div class="no-wrap-cell">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:[`item.persons`]="{ item }">
        <v-row style="padding: 24px">
          <v-chip
            v-for="person in item.persons"
            :key="person.id"
            color="grey-lighten"
            class="osis-chip"
          >
            {{ person.fullname }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.scopes`]="{ item }">
        <v-row style="padding: 24px">
          <v-chip
            v-for="csr_code in item.scopes"
            :key="csr_code.id"
            color="grey-lighten"
            class="osis-chip"
          >
            {{ csr_code.name }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.es_components`]="{ item }">
        <v-row style="padding: 24px">
          <v-chip
            v-for="csr_code in item.es_components"
            :key="csr_code.id"
            color="grey-lighten"
            class="osis-chip"
          >
            {{ csr_code.name }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.time_period`]="{ item }">
        <div
          v-if="item.time_period && item.time_period.lower"
          class="no-wrap-cell"
        >
          {{ item.time_period.lower.split(" ")[0] }} | {{ item.time_period.upper.split(" ")[0] }}
        </div>
      </template>
      <template v-slot:[`item.code_repository`]="{ item }">
        <v-tooltip
          top
          content-class="osis-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="getCodeRepository(item.filtered_data_links)"
              :href="getCodeRepository(item.filtered_data_links)"
              icon
              target="_blank"
              @click.stop
            >
              <v-icon>{{$icons.mdiSourceBranch}}</v-icon>
            </v-btn>
          </template>
          <span>{{getCodeRepository(item.filtered_data_links)}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.data_access`]="{ item }">
        <v-tooltip
          top
          content-class="osis-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="getDataRepository(item.filtered_data_links)"
              :href="getDataRepository(item.filtered_data_links)"
              icon
              target="_blank"
              @click.stop
            >
              <v-icon> {{$icons.mdiDatabase}}</v-icon>
            </v-btn>
          </template>
          <span>{{getDataRepository(item.filtered_data_links)}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.license_spdx`]="{ item }">
        <v-tooltip
          top
          content-class="osis-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="item.license_spdx"
              icon
              :href="'https://spdx.org/licenses/' + item.license_spdx"
              target="_blank"
              @click.stop
            >
              <v-icon> {{$icons.mdiScaleBalance}} </v-icon>
            </v-btn>
          </template>
          <span>{{item.license_spdx}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-tooltip
          top
          content-class="osis-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.status =='prep'"
              v-bind="attrs"
              v-on="on"
            >
              {{$icons.mdiProgressWrench}}
            </v-icon>
            <v-icon
              v-else-if="item.status =='failed'"
              v-bind="attrs"
              v-on="on"
            >
              {{$icons.mdiAlertOctagonOutline}}
            </v-icon>
            <v-icon
              v-else-if="item.status =='running'"
              v-bind="attrs"
              v-on="on"
            >
              {{$icons.mdiMotionPlay}}
            </v-icon>
            <v-icon
              v-else-if="item.status =='finished'"
              v-bind="attrs"
              v-on="on"
            >
              {{$icons.mdiCheckboxMarkedCircle}}
            </v-icon>
          </template>
          <span>{{item.status}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Simulations',
  components: {
    SimulationFormDialog: () => import('@/components/Simulations/SimulationFormDialog.vue'),
    SimulationOverviewDialog: () => import('@/components/Simulations/SimulationOverviewDialog.vue'),
    Autocomplete: () => import('@/components/Basics/Autocomplete.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      test: null,
      postPermission: false,
      nameSearch: '',
      descriptionSearch: '',
      nameAwaitingSearch: false,
      totalSimulations: 0,
      simulations: [],
      abortController: null,
      selectedSimulationId: null,
      loading: true,
      options: {},
      selectedScopeId: null,
      selectedEsComponentId: null,
      selectedContextId: null,
      autocompleteContextKey: 0,
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Scopes',
          value: 'scopes',
          sortable: false,
        },
        {
          text: 'ES Components',
          value: 'es_components',
          sortable: false,
        },
        {
          text: 'Simulated Time Period',
          value: 'time_period',
          sortable: false,
        },
        {
          text: 'Contact Persons',
          value: 'persons',
          sortable: false,
        },
        {
          text: 'Licence',
          value: 'license_spdx',
          sortable: false,
        },
        ,
        {
          text: 'Data Access',
          value: 'data_access',
          sortable: false,
        },
        {
          text: 'Code Repository',
          value: 'code_repository',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
        }
      ],
      showSimulationFormDialog: false,
      showOverviewDialog: false,
      simulationDoesNotExist: false,
      simulationDoesNotExistText: "",
      tableComponentKey: 0
    }
  },
  watch: {
    '$route.params.simulation_id': {
      handler: function (simulation_id) {
        if (simulation_id) {
          this.selectedSimulationId = parseInt(simulation_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLegId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    descriptionSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    },
  },
  async mounted() {
    if (this.$route.params.nameSearch) {
      this.nameSearch = this.$route.params.nameSearch
    }
    if (this.$route.params.contextId) {
      this.$router.replace({ query: { contextId: this.$route.params.contextId } })
    }
    if (this.$route.query.contextId) {
      this.selectedContextId = parseInt(this.$route.query.contextId)
      this.autocompleteContextKey += 1
    }

    await this.getPermissions()
    if (this.$route.params.simulation_id) {
      let simulationExists = await this.doesSimulationExist(this.$route.params.simulation_id)
      if (simulationExists) {
        this.selectedSimulationId = this.$route.params.simulation_id
        this.showOverviewDialog = true
      }
      else {
        this.simulationDoesNotExist = true
        this.simulationDoesNotExistText = 'Simulation ' + this.$route.params.simulation_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.name = this.nameSearch }
      if (this.descriptionSearch) { filter.description = this.descriptionSearch }
      if (this.selectedScopeId) { filter.scopes = [{ 'id': this.selectedScopeId }] }
      if (this.selectedEsComponentId) { filter.es_components = [{ 'id': this.selectedEsComponentId }] }
      if (this.selectedContextId) { filter.contexts = [{ 'id': this.selectedContextId }] }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    simulationsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("simulationsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("simulationsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postPermission': {
          route: '/v1/simulations',
          action: 'POST'
        }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postPermission = permissions.postPermission.permission
    },
    async refresh() {
      await this.getDataFromApi()
      this.tableComponentKey += 1
    },
    redirectHome() {
      this.$router.push({ name: 'Simulations' })
    },
    onSimulationSave(simulation) {
      this.refresh()
      this.showSimulationDialog(simulation)
    },
    showSimulationDialog(simulation) {
      this.$router.push({ name: 'Simulations', params: { simulation_id: simulation.id } })
    },
    addSimulation() {
      this.selectedSimulationId = null
      this.showSimulationFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getSimulations()
        if (data) {
          this.simulations = data.data
          this.totalSimulations = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getSimulations() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'simulations',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesSimulationExist(id) {
      const requestParams = {
        method: "GET",
        url: 'simulations/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    getCodeRepository(data_links) {
      for (const data_link in data_links) {
        if (data_links[data_link].link_type === 'Code Repository') {
          return 'https://' + data_links[data_link].link_path
        }
      }
    },
    getDataRepository(data_links) {
      let repository = null
      for (const data_link in data_links) {
        if (data_links[data_link].link_type === 'Open Data Repository') {
          repository = data_links[data_link]
        }
      }
      if (!repository) {
        for (const data_link in data_links) {
          if (data_links[data_link].link_type === 'Internal Data Repository') {
            repository = data_links[data_link]
          }
        }
      }
      if (repository) {
        if (repository.protocol === 'http://hdl.handle.net/') {
          return 'https://hdl.handle.net/' + repository.link_path
        }
        else if (repository.protocol === 'http://dx.doi.org/') {
          return 'https://doi.org/' + repository.link_path
        }
        else {
          return 'https://' + repository.link_path
        }
      }
    },
    contextInput() {
      this.$router.replace({ query: { contextId: this.selectedContextId } });
      this.filterInput()
    },
    filterInput() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>

<style>
</style>