var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-14 mt-6"},[_c('h4',{staticClass:"display-1 mb-4"},[_vm._v("Devices")]),(_vm.deviceDoesNotExist)?_c('ErrorAlert',{on:{"close":function($event){_vm.deviceDoesNotExist = false}}},[_vm._v(" "+_vm._s(_vm.deviceDoesNotExistText)+" ")]):_vm._e(),(_vm.showGearFormDialog)?_c('GearFormDialog',{attrs:{"visible":_vm.showGearFormDialog,"title":"New Device"},on:{"saved":function($event){_vm.showGearFormDialog = false;
      _vm.onGearSave($event);},"close":function($event){_vm.showGearFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showOverviewDialog)?_c('GearOverviewDialog',{attrs:{"visible":_vm.showOverviewDialog,"gear-id":_vm.selectedGearId},on:{"close":function($event){_vm.showOverviewDialog = false;
      _vm.refresh();
      _vm.redirectHome();}}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Name","label":"Name","persistent-hint":""},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-spacer'),(_vm.postPermission)?_c('v-col',{staticClass:"text-right",attrs:{"sm":"2"}},[_c('v-btn',{staticClass:"elevation-1 plus-button",attrs:{"fab":"","small":""},on:{"click":_vm.addGear}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPlus)+" ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.gears,"options":_vm.options,"server-items-length":_vm.totalGears,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.gearsItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.gearsItemsPerPage=$event},"update:items-per-page":function($event){_vm.gearsItemsPerPage=$event},"click:row":_vm.showGearDialog},scopedSlots:_vm._u([{key:`item.csr_codes`,fn:function({ item }){return [_c('v-row',{staticStyle:{"padding":"24px"}},_vm._l((item.csr_codes),function(csr_code){return _c('v-chip',{key:csr_code.id,staticClass:"osis-chip",attrs:{"color":"grey-lighten"}},[_vm._v(" "+_vm._s(csr_code.abbreviation)+" ")])}),1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }