var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.deliverableDoesNotExist)?_c('ErrorAlert',{on:{"close":function($event){_vm.deliverableDoesNotExist = false}}},[_vm._v(" "+_vm._s(_vm.deliverableDoesNotExistText)+" ")]):_vm._e(),(_vm.showDeliverableFormDialog)?_c('DeliverableFormDialog',{attrs:{"visible":_vm.showDeliverableFormDialog,"leg-id":_vm.legId,"deliverable-id":_vm.selectedDeliverableId},on:{"saved":function($event){_vm.showDeliverableFormDialog = false;
      _vm.refresh();},"close":function($event){_vm.showDeliverableFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showDeleteDialog)?_c('DeleteDialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Delete Deliverable?","warning":_vm.notDeletedAlertText},on:{"delete-confirmed":_vm.onDeleteConfirmed,"close":function($event){_vm.showDeleteDialog = false;
      _vm.notDeletedAlertText = '';
      _vm.refresh();}}}):_vm._e(),(_vm.showDeliverableOverviewDialog)?_c('DeliverableOverviewDialog',{attrs:{"visible":_vm.showDeliverableOverviewDialog,"deliverable-id":_vm.selectedDeliverableId,"leg-id":_vm.legId},on:{"close":function($event){_vm.showDeliverableOverviewDialog = false;
      _vm.refresh();
      _vm.redirectHome();}}}):_vm._e(),_c('v-data-table',{key:_vm.dataTableForceRefresh,staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.deliverables,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100, -1],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.deliverablesItemsPerPage},on:{"update:itemsPerPage":function($event){_vm.deliverablesItemsPerPage=$event},"update:items-per-page":function($event){_vm.deliverablesItemsPerPage=$event},"click:row":_vm.showDeliverableDialog},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',[(_vm.postPermission)?_c('v-btn',{staticClass:"elevation-1 plus-button",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.addDeliverable()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPlus)+" ")])],1):_vm._e()],1)],1)],1)]},proxy:true},{key:`item.flag`,fn:function({ item }){return [_c('div',{class:_vm.flagColours[item.id]},[_vm._v(_vm._s(item.flag))])]}},{key:`item.due`,fn:function({ item }){return [(item.flag!='PUBLISHED')?_c('div',[_vm._v(" "+_vm._s(item.due.trim().split(/\s+/)[0])+" ")]):_vm._e()]}},{key:`item.file`,fn:function({ item }){return [(item.file)?_c('div',[_vm._v(" "+_vm._s(item.file.name)+" "),(item.file.description)?_c('div',[_vm._v("("+_vm._s(item.file.description)+")")]):_vm._e()]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-row',[(
            _vm.editPermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"osis-orange mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editDeliverable(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")]),(
            _vm.deletePermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"osis-red mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDeliverable(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }