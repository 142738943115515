export default {
	methods: {
		extractFirstErrorMessage(jsonString) {
			// Parse the JSON string into an object
			const jsonObj = JSON.parse(jsonString.replace(/'/g, '"'));
	  
			// Iterate over the object keys
			for (let key in jsonObj) {
			  if (jsonObj.hasOwnProperty(key)) {
				// Return the first message found
				return jsonObj[key][0];
			  }
			}
	  
			// If no message is found, return null
			return null;
		  }
	}
}