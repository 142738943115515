<template>
  <div class="mx-14 mt-6">
    <h4 class="display-1 mb-4">Affiliations</h4>
    <ErrorAlert
      v-if="affiliationDoesNotExist"
      @close="affiliationDoesNotExist = false"
    >
      {{ affiliationDoesNotExistText }}
    </ErrorAlert>
    <AffiliationFormDialog
      v-if="showAffiliationFormDialog"
      :visible="showAffiliationFormDialog"
      title="New Affiliation"
      @saved="
        showAffiliationFormDialog = false;
        onAffiliationSave($event);
      "
      @close="
        showAffiliationFormDialog = false;
        refresh();
      "
    >
    </AffiliationFormDialog>
    <AffiliationOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :affiliation-id="selectedAffiliationId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </AffiliationOverviewDialog>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addAffiliation"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="affiliations"
      :options.sync="options"
      :server-items-length="totalAffiliations"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="affiliationsItemsPerPage"
      @click:row="showAffiliationDialog"
    >

    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Affiliations',
  components: {
    AffiliationFormDialog: () => import('@/components/Affiliations/AffiliationFormDialog.vue'),
    AffiliationOverviewDialog: () => import('@/components/Affiliations/AffiliationOverviewDialog.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postPermission: false,
      nameSearch: '',
      nameAwaitingSearch: false,
      totalAffiliations: 0,
      affiliations: [],
      abortController: null,
      selectedAffiliationId: null,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Label',
          value: 'label'
        },
        {
          text: 'Acronym',
          value: 'acronym'
        },
        {
          text: 'Country',
          value: 'country'
        },
        {
          text: 'Nodccode',
          value: 'nodc_code'
        },
        {
          text: 'Wodcode',
          value: 'wod_code'
        }
      ],
      showAffiliationFormDialog: false,
      showOverviewDialog: false,
      affiliationDoesNotExist: false,
      affiliationDoesNotExistText: ""
    }
  },
  watch: {
    '$route.params.affiliation_id': {
      handler: function (affiliation_id) {
        if (affiliation_id || affiliation_id === 0) {
          this.selectedAffiliationId = parseInt(affiliation_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLegId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    firstNameSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    emailSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    }
  },
  async mounted() {
    await this.getPermissions()
    if (this.$route.params.affiliation_id) {
      let affiliationExists = await this.doesAffiliationExist(this.$route.params.affiliation_id)
      if (affiliationExists) {
        this.selectedAffiliationId = this.$route.params.affiliation_id
        this.showOverviewDialog = true
      }
      else {
        this.affiliationDoesNotExist = true
        this.affiliationDoesNotExistText = 'Affiliation ' + this.$route.params.affiliation_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.search_string = this.nameSearch }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    affiliationsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("affiliationsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("affiliationsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postPermission': {
          route: '/v1/affiliations',
          action: 'POST'
        }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postPermission = permissions.postPermission.permission
    },
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'Affiliations' })
    },
    onAffiliationSave(affiliation) {
      this.refresh()
      this.showAffiliationDialog(affiliation)
    },
    showAffiliationDialog(affiliation) {
      this.$router.push({ name: 'Affiliations', params: { affiliation_id: affiliation.id } })
    },
    addAffiliation() {
      this.selectedAffiliationId = null
      this.showAffiliationFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getAffiliations()
        if (data) {
          this.affiliations = data.data
          this.totalAffiliations = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getAffiliations() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'affiliations',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesAffiliationExist(id) {
      const requestParams = {
        method: "GET",
        url: 'affiliations/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    searchFromApi() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1
          this.getDataFromApi()
          this.awaitingSearch = false
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  },
}
</script>

<style>
</style>