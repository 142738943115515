<template>
  <div class="mx-14 mt-6">
    <h4 class="display-1 mb-4">Projects</h4>
    <ErrorAlert
      v-if="projectDoesNotExist"
      @close="projectDoesNotExist = false"
    >
      {{ projectDoesNotExistText }}
    </ErrorAlert>
    <ProjectFormDialog
      v-if="showProjectFormDialog"
      :visible="showProjectFormDialog"
      title="New Project"
      @saved="
        showProjectFormDialog = false;
        onProjectSave($event);
      "
      @close="
        showProjectFormDialog = false;
        refresh();
      "
    >
    </ProjectFormDialog>
    <ProjectOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :project-id="selectedProjectId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </ProjectOverviewDialog>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col sm="2">
        <v-autocomplete
          v-if="getPersonPermission"
          clearable
          v-model="principalInvestigator.id"
          :loading="principalInvestigator.loading"
          :items="principalInvestigator.items"
          item-text="fullname"
          item-value="id"
          :search-input.sync="principalInvestigator.search"
          hide-no-data
          hint="Type surname to search"
          persistent-hint
          label="PI"
          append-icon=""
        ></v-autocomplete>
      </v-col>
      <v-col
        sm="3"
        align="center"
      >
        <v-select
          :items="sponsorItems"
          v-model="selectedSponsors"
          label="Funded by"
          multiple
          attach
          @input="getDataFromApi"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.acronym }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedSponsors.length - 1 }} others)
            </span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action>
              <v-icon left>{{ getIcon(item) }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.acronym }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="toggleSponsor"
            >
              <v-list-item-action>
                <v-icon :color="selectedSponsors.length > 0 ? 'indigo darken-4' : ''">
                  {{ toggleSponsorIcon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addProject"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="projects"
      :options.sync="options"
      :server-items-length="totalProjects"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="projectsItemsPerPage"
      @click:row="showProjectDialog"
    >
      <template v-slot:[`item.csr_codes`]="{ item }">
        <v-row style="padding: 24px">
          <v-chip
            v-for="csr_code in item.csr_codes"
            :key="csr_code.id"
            color="grey-lighten"
            class="osis-chip"
          >
            {{ csr_code.abbreviation }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.research_units`]="{ item }">
        <v-row style="padding: 24px">
          <v-chip
            v-for="research_unit in item.research_units"
            :key="research_unit.id"
            color="grey-lighten"
            class="osis-chip"
          >
            {{ research_unit.name }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.sponsors`]="{ item }">
        <v-row style="padding: 24px">
          <v-chip
            v-for="funding in item.fundings"
            :key="funding.id"
            color="grey-lighten"
            class="osis-chip"
          >
            {{ funding.sponsor }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.web_visibility`]="{ item }">
        <v-icon v-if="item.web_info_visible">
          {{$icons.mdiCheck}}
        </v-icon>
      </template>
      <template v-slot:[`item.period`]="{ item }">
        {{ item.project_start.trim().split('-')[0] }}-{{item.project_start.trim().split('-')[1]}} | {{ item.project_end.trim().split('-')[0] }}-{{item.project_end.trim().split('-')[1]}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Projects',
  components: {
    ProjectFormDialog: () => import('@/components/Projects/ProjectFormDialog.vue'),
    ProjectOverviewDialog: () => import('@/components/Projects/ProjectOverviewDialog.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postPermission: false,
      nameSearch: '',
      getPersonPermission: false,
      principalInvestigator: {
        id: null,
        loading: false,
        search: '',
        items: []
      },
      nameAwaitingSearch: false,
      totalProjects: 0,
      projects: [],
      abortController: null,
      selectedProjectId: null,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Acronym',
          value: 'acronym'
        },
        {
          text: 'PI',
          value: 'principal_investigator',
          sortable: false,
        },
        {
          text: 'Reasearch Units',
          value: 'research_units',
          sortable: false,
        },
        {
          text: 'Funded By',
          value: 'sponsors',
          sortable: false,
        },
        {
          text: 'Project Funding Period',
          value: 'period',
          sortable: false,
        },
        {
          text: 'Web Visibility',
          value: 'web_visibility',
          align: 'center',
          sortable: false,
        }
      ],
      showProjectFormDialog: false,
      showOverviewDialog: false,
      projectDoesNotExist: false,
      projectDoesNotExistText: "",
      sponsorItems: [],
      selectedSponsors: []
    }
  },
  watch: {
    '$route.params.project_id': {
      handler: function (project_id) {
        if (project_id || project_id === 0) {
          this.selectedProjectId = parseInt(project_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLegId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    },
    'principalInvestigator.search': function (val, oldVal) {
      val && val.length > 2 && !val.includes(',') && this.queryPrincipalInvestigator(val)
    },
    'principalInvestigator.id': {
      handler() {
        this.getDataFromApi()
      }
    },
  },
  async mounted() {
    await this.getPermissions()
    if (this.$route.params.project_id) {
      let projectExists = await this.doesProjectExist(this.$route.params.project_id)
      if (projectExists) {
        this.selectedProjectId = this.$route.params.project_id
        this.showOverviewDialog = true
      }
      else {
        this.projectDoesNotExist = true
        this.projectDoesNotExistText = 'Project ' + this.$route.params.project_id + ' does not exist'
        this.redirectHome()
      }
    }
    this.sponsorItems = await this.getSponsors()
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.name = this.nameSearch }
      if (this.principalInvestigator.id || this.principalInvestigator.id === 0) { filter.principal_investigator_id = this.principalInvestigator.id }
      if (this.selectedSponsors.length > 0) { filter.sponsors = this.selectedSponsors }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    allSponsorsSelected() {
      return this.selectedSponsors.length == this.sponsorItems.length
    },
    someSponsorsSelcted() {
      return this.selectedSponsors.length > 0 && !this.allSponsorsSelected
    },
    toggleSponsorIcon() {
      if (this.allSponsorsSelected) return this.$icons.mdiCloseBox;
      if (this.someSponsorsSelcted) return this.$icons.mdiMinusBox;
      return this.$icons.mdiCheckboxBlankOutline;
    },
    projectsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("projectsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("projectsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postPermission': { route: '/v1/projects', action: 'POST' },
        'getPersonPermission': { route: '/v1/persons/short', action: 'GET' }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postPermission = permissions.postPermission.permission
      this.getPersonPermission = permissions.getPersonPermission.permission
    },
    async queryPrincipalInvestigator(filter) {
      this.principalInvestigator.loading = true
      this.principalInvestigator.items = await this.getPersonsNamesFilter(filter)
      this.principalInvestigator.loading = false
    },
    async getPersonsNamesFilter(filter) {
      const requestParams = {
        method: "GET",
        url: 'persons/short',
        queryParams: {
          filter: '{"lastname":"' + filter + '"}'
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        const data = await res.json()
        return data
      } catch (error) {
        console.log(error)
      }
    },
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'Projects' })
    },
    onProjectSave(project) {
      this.refresh()
      this.showProjectDialog(project)
    },
    showProjectDialog(project) {
      this.$router.push({ name: 'Projects', params: { project_id: project.id } })
    },
    addProject() {
      this.selectedProjectId = null
      this.showProjectFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getProjects()
        if (data) {
          this.projects = data.data
          this.totalProjects = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getProjects() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'projects',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesProjectExist(id) {
      const requestParams = {
        method: "GET",
        url: 'projects/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    searchFromApi() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1
          this.getDataFromApi()
          this.awaitingSearch = false
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    async getSponsors() {
      const requestParams = {
        method: "GET",
        url: 'sponsors/short',
        queryParams: {
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        const data = await res.json()
        return data
      } catch (error) {
        console.log(error)
      }
    },
    toggleSponsor() {
      this.$nextTick(() => {
        if (this.allSponsorsSelected) {
          this.selectedSponsors = []
        } else {
          this.selectedSponsors = this.sponsorItems.map(item => item)
        }
        this.getDataFromApi()
      })
    },
    getIcon(item) {
      if (item && item.id) {
        let selectedSponsorsIds = []
        selectedSponsorsIds = this.selectedSponsors.map(sponsor => {
          return sponsor.id
        })
        return selectedSponsorsIds.includes(item.id) ? this.$icons.mdiCheckboxMarked : this.$icons.mdiCheckboxBlankOutline
      }
    },
  },
}
</script>

<style>
</style>