<template>
  <div>
    <v-app>
      <Navbar />
      <v-main>
        <router-view></router-view>
      </v-main>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import * as mdiIcons from '@mdi/js';
Vue.prototype.$icons = mdiIcons;

export default {
  components: { Navbar, Footer },
  name: 'App',

  data: () => ({
    //
  }),
  beforeCreate() {
    // get vuex values from localStorage
    this.$store.commit('initialiseStore');
  },
};
</script>

<style src="./assets/osis.css">
</style>
