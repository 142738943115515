var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showLinkFormDialog)?_c('DataLinksFormDialog',{attrs:{"visible":_vm.showLinkFormDialog,"leg-id":_vm.linkFormProps.legId,"event-id":_vm.linkFormProps.eventId,"event-leg-id":_vm.linkFormProps.eventLegId,"cruise-id":_vm.linkFormProps.cruiseId,"experiment-id":_vm.linkFormProps.experimentId,"simulation-id":_vm.linkFormProps.simulationId,"event-network-id":_vm.linkFormProps.eventNetworkId,"link-id":_vm.linkFormProps.linkId,"title":"Link"},on:{"saved":function($event){_vm.showLinkFormDialog = false;
      _vm.refresh();},"close":function($event){_vm.showLinkFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showDeleteDialog)?_c('DeleteDialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Delete Link?","warning":_vm.notDeletedAlertText},on:{"delete-confirmed":_vm.onDeleteConfirmed,"close":function($event){_vm.showDeleteDialog = false;
      _vm.notDeletedAlertText = '';
      _vm.refresh();}}}):_vm._e(),(_vm.showEventOverviewDialog)?_c('EventOverviewDialog',{attrs:{"visible":_vm.showEventOverviewDialog,"event-id":_vm.selectedEventId,"leg-id":_vm.legId},on:{"close":function($event){_vm.showEventOverviewDialog = false;
      _vm.refresh()}}}):_vm._e(),(_vm.data_links.length)?_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$icons.mdiFilter)+" ")])],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"sm":"6","align":"center"}},[_c('v-select',{attrs:{"items":_vm.typeItems,"label":"Filter Types","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTypes.length - 1)+" others) ")]):_vm._e()]}},{key:"item",fn:function({ item }){return [_c('v-list-item-action',[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getIcon(item)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleTypes}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedTypes.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.toggleTypesIcon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,false,4134187393),model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}})],1)],1)],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.data_links,"loading":_vm.loading,"item-key":"name","footer-props":{
      'items-per-page-options': [10, 50, 100, -1],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.dataLinksItemsPerPage},on:{"update:itemsPerPage":function($event){_vm.dataLinksItemsPerPage=$event},"update:items-per-page":function($event){_vm.dataLinksItemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',[(_vm.postPermission)?_c('v-btn',{staticClass:"elevation-1 plus-button",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.addLink()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPlus)+" ")])],1):_vm._e()],1)],1)],1)]},proxy:true},{key:`item.link_path`,fn:function({ item }){return [(item.protocol==='http://hdl.handle.net/')?_c('a',{attrs:{"href":'https://hdl.handle.net/' + item.link_path,"target":"_blank"}},[_vm._v("https://hdl.handle.net/"+_vm._s(item.link_path)+" ")]):(item.protocol==='http://dx.doi.org/')?_c('a',{attrs:{"href":'https://doi.org/' + item.link_path,"target":"_blank"}},[_vm._v("https://doi.org/"+_vm._s(item.link_path)+" ")]):(item.protocol==='http://')?_c('a',{attrs:{"href":'http://' + item.link_path,"target":"_blank"}},[_vm._v("http://"+_vm._s(item.link_path)+" ")]):_c('a',{attrs:{"href":'https://' + item.link_path,"target":"_blank"}},[_vm._v("https://"+_vm._s(item.link_path)+" ")])]}},{key:`item.related_to`,fn:function({ item }){return [(item.leg)?_c('div',[_c('v-chip',{staticClass:"osis-chip leg-chip"},[_vm._v(" Expedition "+_vm._s(item.leg.name)+" ")])],1):_vm._e(),(item.event)?_c('div',[_c('v-chip',{staticClass:"osis-chip event-chip",on:{"click":function($event){return _vm.showEventDialog(item.event.id)}}},[_c('div',[_vm._v(" Event "+_vm._s(item.event.label)+" ")])])],1):_vm._e(),(item.cruise)?_c('div',[_c('v-chip',{staticClass:"osis-chip cruise-chip"},[_vm._v(" Cruise "+_vm._s(item.cruise.label)+" ")])],1):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-row',[(
            _vm.editPermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"osis-orange mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editLink(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")]),(
            _vm.deletePermission
          )?_c('v-tooltip',{attrs:{"top":"","content-class":"osis-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"osis-red mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteLink(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }