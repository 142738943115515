<template>
  <div class="mx-14 mt-6">
    <h4 class="display-1 mb-4">Persons</h4>
    <ErrorAlert
      v-if="personDoesNotExist"
      @close="personDoesNotExist = false"
    >
      {{ personDoesNotExistText }}
    </ErrorAlert>
    <PersonFormDialog
      v-if="showPersonFormDialog"
      :visible="showPersonFormDialog"
      title="New Person"
      @saved="
        showPersonFormDialog = false;
        onPersonSave($event);
      "
      @close="
        showPersonFormDialog = false;
        refresh();
      "
    >
    </PersonFormDialog>
    <PersonOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :person-id="selectedPersonId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </PersonOverviewDialog>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Lastname"
          label="Lastname"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col sm="2">
        <v-text-field
          v-model="firstNameSearch"
          hint="Search Firstname"
          label="Firstname"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col sm="2">
        <v-text-field
          v-model="emailSearch"
          hint="Search Email"
          label="Email"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-model="selectedContextId"
          label="Community Context"
          url="contexts"
          item-text="name"
          item-value="id"
          @input="contextInput"
          :key=autocompleteContextKey
        ></Autocomplete>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addPerson"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="persons"
      :options.sync="options"
      :server-items-length="totalPersons"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="personsItemsPerPage"
      @click:row="showPersonDialog"
    >
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Persons',
  components: {
    PersonFormDialog: () => import('@/components/Persons/PersonFormDialog.vue'),
    PersonOverviewDialog: () => import('@/components/Persons/PersonOverviewDialog.vue'),
    Autocomplete: () => import('@/components/Basics/Autocomplete.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postPermission: false,
      nameSearch: '',
      firstNameSearch: '',
      emailSearch: '',
      nameAwaitingSearch: false,
      totalPersons: 0,
      persons: [],
      abortController: null,
      selectedPersonId: null,
      selectedContextId: null,
      autocompleteContextKey: 0,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Lastname',
          value: 'lastname'
        },
        {
          text: 'Firstname',
          value: 'firstname'
        },
        {
          text: 'Email address',
          value: 'emailaddress'
        },
        {
          text: 'Institution',
          value: 'affiliation',
          sortable: false,
        },
        {
          text: 'Portal User ID',
          value: 'portal_user_id'
        }
      ],
      showPersonFormDialog: false,
      showOverviewDialog: false,
      personDoesNotExist: false,
      personDoesNotExistText: ""
    }
  },
  watch: {
    '$route.params.person_id': {
      handler: function (person_id) {
        if (person_id || person_id === 0) {
          this.selectedPersonId = parseInt(person_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLegId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    firstNameSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    emailSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    }
  },
  async mounted() {
    if (this.$route.params.contextId) {
      this.$router.replace({ query: { contextId: this.$route.params.contextId } })
    }
    if (this.$route.query.contextId) {
      this.selectedContextId = parseInt(this.$route.query.contextId)
      this.autocompleteContextKey += 1
    }
    await this.getPermissions()
    if (this.$route.params.person_id) {
      let personExists = await this.doesPersonExist(this.$route.params.person_id)
      if (personExists) {
        this.selectedPersonId = this.$route.params.person_id
        this.showOverviewDialog = true
      }
      else {
        this.personDoesNotExist = true
        this.personDoesNotExistText = 'Person ' + this.$route.params.person_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.lastname = this.nameSearch }
      if (this.firstNameSearch) { filter.firstname = this.firstNameSearch }
      if (this.emailSearch) { filter.emailaddress = this.emailSearch }
      if (this.selectedContextId) { filter.contexts = [{ 'id': this.selectedContextId }] }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    personsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("personsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("personsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postPermission': {
          route: '/v1/persons',
          action: 'POST'
        }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postPermission = permissions.postPermission.permission
    },
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'Persons' })
    },
    onPersonSave(person) {
      this.refresh()
      this.showPersonDialog(person)
    },
    showPersonDialog(person) {
      this.$router.push({ name: 'Persons', params: { person_id: person.id } })
    },
    addPerson() {
      this.selectedPersonId = null
      this.showPersonFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getPersons()
        if (data) {
          this.persons = data.data
          this.totalPersons = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getPersons() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'persons',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesPersonExist(id) {
      const requestParams = {
        method: "GET",
        url: 'persons/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    searchFromApi() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1
          this.getDataFromApi()
          this.awaitingSearch = false
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    contextInput() {
      this.$router.replace({ query: { contextId: this.selectedContextId } });
      this.filterInput()
    },
    filterInput() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>

<style>
</style>