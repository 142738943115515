<template>
  <div class="mx-14 mt-6">
    <h4 class="display-1 mb-4">Devices</h4>
    <ErrorAlert
      v-if="deviceDoesNotExist"
      @close="deviceDoesNotExist = false"
    >
      {{ deviceDoesNotExistText }}
    </ErrorAlert>
    <GearFormDialog
      v-if="showGearFormDialog"
      :visible="showGearFormDialog"
      title="New Device"
      @saved="
        showGearFormDialog = false;
        onGearSave($event);
      "
      @close="
        showGearFormDialog = false;
        refresh();
      "
    >
    </GearFormDialog>
    <GearOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :gear-id="selectedGearId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </GearOverviewDialog>
    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addGear"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="gears"
      :options.sync="options"
      :server-items-length="totalGears"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="gearsItemsPerPage"
      @click:row="showGearDialog"
    >
      <template v-slot:[`item.csr_codes`]="{ item }">
        <v-row style="padding: 24px">
          <v-chip
            v-for="csr_code in item.csr_codes"
            :key="csr_code.id"
            color="grey-lighten"
            class="osis-chip"
          >
            {{ csr_code.abbreviation }}
          </v-chip>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Gears',
  components: {
    GearFormDialog: () => import('@/components/Gears/GearFormDialog.vue'),
    GearOverviewDialog: () => import('@/components/Gears/GearOverviewDialog.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postPermission: false,
      nameSearch: '',
      nameAwaitingSearch: false,
      totalGears: 0,
      gears: [],
      abortController: null,
      selectedGearId: null,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Model/Make',
          value: 'model_make'
        },
        {
          text: 'Abbreviation',
          value: 'abbreviation'
        },
        {
          text: 'Gear Type',
          value: 'gear_type',
          sortable: false,
        },
        {
          text: 'CSR Categorys',
          value: 'csr_codes',
          sortable: false,
        }
      ],
      showGearFormDialog: false,
      showOverviewDialog: false,
      deviceDoesNotExist: false,
      deviceDoesNotExistText: ""
    }
  },
  watch: {
    '$route.params.gear_id': {
      handler: function (gear_id) {
        if (gear_id || gear_id === 0) {
          this.selectedGearId = parseInt(gear_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLegId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    }
  },
  async mounted() {
    await this.getPermissions()
    if (this.$route.params.gear_id) {
      let deviceExists = await this.doesDeviceExist(this.$route.params.gear_id)
      if (deviceExists) {
        this.selectedGearId = this.$route.params.gear_id
        this.showOverviewDialog = true
      }
      else {
        this.deviceDoesNotExist = true
        this.deviceDoesNotExistText = 'Device ' + this.$route.params.gear_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.search_string = this.nameSearch }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    gearsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("gearsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("gearsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postPermission': {
          route: '/v1/gears',
          action: 'POST'
        }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postPermission = permissions.postPermission.permission
    },
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'Gears' })
    },
    onGearSave(gear) {
      this.refresh()
      this.showGearDialog(gear)
    },
    showGearDialog(gear) {
      this.$router.push({ name: 'Gears', params: { gear_id: gear.id } })
    },
    addGear() {
      this.selectedGearId = null
      this.showGearFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getGears()
        if (data) {
          this.gears = data.data
          this.totalGears = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getGears() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'gears',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesDeviceExist(id) {
      const requestParams = {
        method: "GET",
        url: 'gears/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
  },
}
</script>

<style>
</style>