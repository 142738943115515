var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-14 mt-6"},[(_vm.eventNetworkDoesNotExist)?_c('ErrorAlert',{on:{"close":function($event){_vm.eventNetworkDoesNotExist = false}}},[_vm._v(" "+_vm._s(_vm.eventNetworkDoesNotExistText)+" ")]):_vm._e(),_c('h4',{staticClass:"display-1 mb-4"},[_vm._v("Networks")]),(_vm.showOverviewDialog)?_c('EventNetworkOverviewDialog',{attrs:{"visible":_vm.showOverviewDialog,"event-network-id":_vm.selectedEventNetworkId},on:{"close":function($event){_vm.showOverviewDialog = false;
      _vm.refresh();
      _vm.redirectHome();}}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Name","label":"Name","persistent-hint":""},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.eventNetworks,"options":_vm.options,"server-items-length":_vm.totalEventNetwork,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.eventNetworksItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.eventNetworksItemsPerPage=$event},"update:items-per-page":function($event){_vm.eventNetworksItemsPerPage=$event},"click:row":_vm.showEventNetworkDialog},scopedSlots:_vm._u([{key:`item.files`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.file_count+item.event_file_count_sum)+" ")]}},{key:`item.links`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.data_link_count+item.event_data_link_count_sum)+" ")]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }