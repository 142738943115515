<template>
  <div class="mx-14 mt-6">
    <LegOverviewDialog
      ref="legOverviewDialog"
      v-if="showLegDialog"
      :visible="showLegDialog"
      :leg-id="selectedLegId"
      @close="
        showLegDialog = false
        refresh()
      "
    >
    </LegOverviewDialog>
    <EventOverviewDialog
      v-if="showEventDialog"
      :visible="showEventDialog"
      :event-id="selectedEventId"
      :leg-id="selectedEventLegId"
      @close="
        showEventDialog = false;
        refresh()
      "
    >
    </EventOverviewDialog>
    <CruiseOverviewDialog
      v-if="showCruiseDialog"
      :visible="showCruiseDialog"
      :cruise-id="selectedCruiseId"
      @close="
        showCruiseDialog = false;
        refresh()
      "
    >
    </CruiseOverviewDialog>
    <ExperimentOverviewDialog
      v-if="showExperimentDialog"
      :visible="showExperimentDialog"
      :experiment-id="selectedExperimentId"
      @close="
        showExperimentDialog = false;
        refresh()
      "
    >
    </ExperimentOverviewDialog>
    <SimulationOverviewDialog
      v-if="showSimulationDialog"
      :visible="showSimulationDialog"
      :simulation-id="selectedSimulationId"
      @close="
        showSimulationDialog = false;
        refresh()
      "
    >
    </SimulationOverviewDialog>
    <DataLinksFormDialog
      v-if="showLinkFormDialog"
      :visible="showLinkFormDialog"
      :leg-id="selectedLink.leg ? selectedLink.leg.id : null"
      :event-id="selectedLink.event ? selectedLink.event.id : null"
      :event-leg-id="selectedLink.event ? selectedLink.event.leg_id : null"
      :cruise-id="selectedLink.cruise ? selectedLink.cruise.id : null"
      :experiment-id="selectedLink.experiment ? selectedLink.experiment.id : null"
      :simulation-id="selectedLink.simulation ? selectedLink.simulation.id : null"
      :model-experiment-id="selectedLink.model_experiment ? selectedLink.model_experiment.id : null"
      :event-network-id="selectedLink.event_network ? selectedLink.event_network.network_id : null"
      :link-id="selectedLink.id"
      title="Link"
      @saved="
        showLinkFormDialog = false;
        refresh();
      "
      @close="
        showLinkFormDialog = false;
        refresh();
      "
    >
    </DataLinksFormDialog>
    <DeleteDialog
      :visible="showDeleteDialog"
      @delete-confirmed="onDeleteConfirmed"
      @close="showDeleteDialog = false"
      title="Delete Link?"
    >
    </DeleteDialog>
    <h4 class="display-1 mb-4">All Data Links</h4>

    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="nameSearch"
          hint="Search in description and link path"
          label="Search"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col
        sm="6"
        align="center"
      >
        <v-select
          :items="typeItems"
          v-model="selectedTypes"
          label="Filter Types"
          multiple
          attach
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedTypes.length - 1 }} others)
            </span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action>
              <v-icon left>{{ getIcon(item) }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="toggleTypes"
            >
              <v-list-item-action>
                <v-icon :color="selectedTypes.length > 0 ? 'indigo darken-4' : ''">
                  {{ toggleTypesIcon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-data-table
      class="osis-table elevation-2"
      :headers="headers"
      :items="dataLinks"
      :options.sync="options"
      :server-items-length="totalDataLinks"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="totalDataLinksItemsPerPage"
    >
      <template v-slot:[`item.related_to`]="{ item }">
        <div v-if="item.leg">
          <v-chip
            class="osis-chip leg-chip"
            @click="showLeg(item.leg.id)"
          >
            Leg {{ item.leg.name }}
          </v-chip>
        </div>
        <div v-if="item.event">
          <v-chip
            class="osis-chip event-chip "
            @click="showEvent(item.event.id, item.event.leg_id)"
          >
            <div>
              Event {{ item.event.label }}
            </div>
          </v-chip>
        </div>
        <div v-if="item.cruise">
          <v-chip
            class="osis-chip cruise-chip"
            @click="showCruise(item.cruise.id)"
          >
            Cruise {{ item.cruise.label }}
          </v-chip>
        </div>
        <div v-if="item.experiment">
          <v-chip
            class="osis-chip experiment-chip"
            @click="showExperiment(item.experiment.id)"
          >
            <div>
              Experiment {{ item.experiment.label }}
            </div>
          </v-chip>
        </div>
        <div v-if="item.simulation">
          <v-chip
            class="osis-chip simulation-chip"
            @click="showSimulation(item.simulation.id)"
          >
            <div>
              Simulation {{ item.simulation.name }}
            </div>
          </v-chip>
        </div>
        <div v-if="item.simulation===null">
          <v-chip class="osis-chip simulation-chip">
            <div>
              Model
            </div>
          </v-chip>
        </div>
        <div v-if="item.event_network">
          <v-chip
            class="osis-chip"
            color="green accent-1"
          >
            Event Network {{ item.event_network.networkname }}
          </v-chip>
        </div>
        <div v-if="item.model_experiment">
          <v-chip
            class="osis-chip"
            color="pink accent-1"
          >
            Model Experiment {{ item.model_experiment.name }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.link_path`]="{ item }">
        <a
          v-if="item.protocol==='http://hdl.handle.net/'"
          v-bind:href="'https://hdl.handle.net/' + item.link_path"
          target="_blank"
        >https://hdl.handle.net/{{ item.link_path }}
        </a>
        <a
          v-else-if="item.protocol==='http://dx.doi.org/'"
          v-bind:href="'https://doi.org/' + item.link_path"
          target="_blank"
        >https://doi.org/{{ item.link_path }}
        </a>
        <a
          v-else-if="item.protocol==='http://'"
          v-bind:href="'http://' + item.link_path"
          target="_blank"
        >http://{{ item.link_path }}
        </a>
        <a
          v-else
          v-bind:href="'https://' + item.link_path"
          target="_blank"
        >https://{{ item.link_path }}
        </a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row v-if="item.simulation!==null"> <!-- ignore models (models have simulation === null)-->
          <v-tooltip
            top
            v-if="
              editPermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-orange mr-2"
                @click="editLink(item)"
              >
                {{$icons.mdiPencil}}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiPencil}}
          </v-icon>
          <v-tooltip
            top
            v-if="
              deletePermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-red mr-2"
                @click="deleteLink(item)"
              >
                {{$icons.mdiDeleteOutline}}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiDeleteOutline}}
          </v-icon>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'
import helperFunctions from '@/mixins/helperFunctions'

export default {
  name: 'TotalDataLinks',
  components: {
    DataLinksFormDialog: () => import('@/components/DataLinks/DataLinksFormDialog.vue'),
    DeleteDialog: () => import('@/components/DeleteDialog.vue'),
    LegOverviewDialog: () => import('@/components/Legs/LegOverviewDialog.vue'),
    EventOverviewDialog: () => import('@/components/Events/EventOverviewDialog.vue'),
    CruiseOverviewDialog: () => import('@/components/Cruises/CruiseOverviewDialog.vue'),
    ExperimentOverviewDialog: () => import('@/components/Experiments/ExperimentOverviewDialog.vue'),
    SimulationOverviewDialog: () => import('@/components/Simulations/SimulationOverviewDialog.vue'),
  },
  mixins: [permission, apiRequests, helperFunctions],
  data() {
    return {
      nameSearch: '',
      awaitingSearch: false,
      totalDataLinks: 0,
      dataLinks: [],
      abortController: null,
      loading: true,
      showDeleteDialog: false,
      showLinkFormDialog: false,
      editPermission: false,
      deletePermission: false,
      selectedLink: null,
      selectedLegId: null,
      showLegDialog: false,
      selectedEventId: null,
      selectedEventLegId: null,
      showEventDialog: false,
      selectedCruiseId: null,
      showCruiseDialog: false,
      selectedExperimentId: null,
      showExperimentDialog: false,
      selectedSimulationId: null,
      showSimulationDialog: false,
      options: {},
      headers: [
        {
          text: 'Type of link',
          value: 'link_type'
        },
        {
          text: 'Link',
          value: 'link_path'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Date created',
          value: 'date_created'
        },
        {
          text: 'Related to',
          value: 'related_to',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      typeItems: [
        { text: 'DSHIP', value: "DSHIP" },
        { text: 'Blog', value: "Blog" },
        { text: 'Location', value: "Location" },
        { text: 'Sample Repository', value: "Sample Repository" },
        { text: 'Code Repository', value: "Code Repository" },
        { text: 'Cruise Report', value: "Cruise Report" },
        { text: 'Print Publication', value: "Print Publication" },
        { text: 'URL', value: "URL" },
        { text: 'Open Data Repository', value: "Open Data Repository" },
        { text: 'Internal Data Repository', value: "Internal Data Repository" }
      ],
      selectedTypes: []
    }
  },
  watch: {
    nameSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    selectedTypes: {
      handler() {
        this.getDataFromApi()
      }
    },
    'options': {
      async handler() {
        await this.getDataFromApi()
        await this.getPermissions()
      }
    },
  },
  async mounted() {
    this.selectedTypes = this.typeItems.map(item => item.value)
  },
  computed: {
    filter_string: function () {
      let filter = {
        ...(this.nameSearch && { search_string: this.nameSearch }),
        ...(this.selectedTypes.length > 0 && { types: this.selectedTypes })
      }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    allTypesSelected() {
      return this.selectedTypes.length == this.typeItems.length
    },
    someTypesSelcted() {
      return this.selectedTypes.length > 0 && !this.allTypesSelected
    },
    toggleTypesIcon() {
      if (this.allTypesSelected) return this.$icons.mdiCloseBox;
      if (this.someTypesSelcted) return this.$icons.mdiMinusBox;
      return this.$icons.mdiCheckboxBlankOutline;
    },
    totalDataLinksItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("totalDataLinksItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("totalDataLinksItemsPerPage", value);
      },
    },
  },
  methods: {
    async refresh() {
      await this.getDataFromApi()
      await this.getPermissions()
    },
    searchFromApi() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1
          this.getDataFromApi()
          this.awaitingSearch = false
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    async getDataFromApi() {
      this.loading = true
      if (this.selectedTypes.length == 0) {
        this.dataLinks = []
      } else {
        try {
          let data = await this.getDataLinks()
          if (data) {
            this.dataLinks = data.data
            this.totalDataLinks = data.meta.total
          }
        } catch (error) {
          console.log(error)
        }
      }
      this.loading = false
    },
    async getDataLinks() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'data_links',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async getPermissions() {
      let permissionsToAsk = {
        'editPermission': { route: '/v1/data_links/0', action: 'PATCH' },
        'deletePermission': { route: '/v1/data_links/0', action: 'DELETE' },
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.editPermission = permissions.editPermission.permission
      this.deletePermission = permissions.deletePermission.permission
    },
    deleteLink(link) {
      this.selectedLink = link
      this.showDeleteDialog = true
    },
    async onDeleteConfirmed() {
      let url = 'data_links/' + this.selectedLink.id

      const requestParams = {
        method: "DELETE",
        url: url,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 204) {
          this.showDeleteDialog = false
          await this.refresh()
        }
        else {
          const data = await res.json()
          this.notSavedAlertText = this.extractFirstErrorMessage(data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    editLink(link) {
      this.selectedLink = link
      // Edit link that is not a leg associated link
      if (link.cruise_id) {
        this.selectedLinkCruiseId = link.cruise_id
      } else if (link.event_id) {
        this.selectedLinkEventId = link.event_id
      } else {
        this.selectedLinkCruiseId = null
        this.selectedLinkEventId = null
      }
      this.showLinkFormDialog = true
    },
    showLeg(legId) {
      this.selectedLegId = legId
      this.showLegDialog = true
    },
    showEvent(eventId, legId) {
      this.selectedEventId = eventId
      this.selectedEventLegId = legId
      this.showEventDialog = true
    },
    showCruise(cruiseId) {
      this.selectedCruiseId = cruiseId
      this.showCruiseDialog = true
    },
    showExperiment(experimentId) {
      this.selectedExperimentId = experimentId
      this.showExperimentDialog = true
    },
    showSimulation(simultionId) {
      this.selectedSimulationId = simultionId
      this.showSimulationDialog = true
    },
    toggleTypes() {
      this.$nextTick(() => {
        if (this.allTypesSelected) {
          this.selectedTypes = []
        } else {
          this.selectedTypes = this.typeItems.map(item => item.value)
        }
      })
    },
    getIcon(item) {
      return this.selectedTypes.includes(item.value) ? this.$icons.mdiCheckboxMarked : this.$icons.mdiCheckboxBlankOutline;
    },
  },
}
</script>

<style>
</style>