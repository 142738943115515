<template>
  <div class="mx-14 mt-6">
    <ErrorAlert
      v-if="locationDoesNotExist"
      @close="locationDoesNotExist = false"
    >
      {{ locationDoesNotExistText }}
    </ErrorAlert>
    <h4 class="display-1 mb-4">Locations</h4>
    <LocationFormDialog
      v-if="showLocationFormDialog"
      :visible="showLocationFormDialog"
      title="New Location"
      @saved="
        showLocationFormDialog = false;
        onLocationSave($event);
      "
      @close="
        showLocationFormDialog = false;
        refresh();
      "
    >
    </LocationFormDialog>
    <LocationOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :location-id="selectedLocationId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </LocationOverviewDialog>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addLocation"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="locations"
      :options.sync="options"
      :server-items-length="totalLocations"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="locationsItemsPerPage"
      @click:row="showLocationDialog"
    >
      <template v-slot:[`item.name`]="{ item }">

        <div v-if="item.category == 'Port'">
          {{item.name}} [{{item.category}}]
        </div>
        <div v-else>
          {{item.name}}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Locations',
  components: {
    LocationOverviewDialog: () => import('@/components/Locations/LocationOverviewDialog.vue'),
    LocationFormDialog: () => import('@/components/Locations/LocationFormDialog.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postPermission: false,
      nameSearch: '',
      nameAwaitingSearch: false,
      totalLocations: 0,
      locations: [],
      abortController: null,
      selectedLocationId: null,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Country',
          value: 'country'
        },
        {
          text: 'ID (SeaDataNet Ports)',
          value: 'bodc_entrykey'
        },
        {
          text: 'Latitude',
          value: 'latitude'
        },
        {
          text: 'Longitude',
          value: 'longitude'
        }
      ],
      showLocationFormDialog: false,
      showOverviewDialog: false,
      locationDoesNotExist: false,
      locationDoesNotExistText: ""
    }
  },
  watch: {
    '$route.params.location_id': {
      handler: function (location_id) {
        if (location_id || location_id == 0) {
          this.selectedLocationId = parseInt(location_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLocationId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    }
  },
  async mounted() {
    await this.getPermissions()
    if (this.$route.params.location_id) {
      let locationExists = await this.doesLocationExist(this.$route.params.location_id)
      if (locationExists) {
        this.selectedLocationId = this.$route.params.location_id
        this.showOverviewDialog = true
      }
      else {
        this.locationDoesNotExist = true
        this.locationDoesNotExistText = 'Location ' + this.$route.params.location_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.name = this.nameSearch }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    locationsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("locationsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("locationsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postPermission': {
          route: '/v1/locations',
          action: 'POST'
        }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postPermission = permissions.postPermission.permission
    },
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'Locations' })
    },
    onLocationSave(location) {
      this.refresh()
      this.showLocationDialog(location)
    },
    showLocationDialog(location) {
      this.$router.push({ name: 'Locations', params: { location_id: location.id } })
    },
    addLocation() {
      this.selectedLocationId = null
      this.showLocationFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getLocations()
        if (data) {
          this.locations = data.data
          this.totalLocations = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getLocations() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'locations',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesLocationExist(id) {
      const requestParams = {
        method: "GET",
        url: 'locations/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
  },
}
</script>

<style>
</style>