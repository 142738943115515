<template>
  <div>
    <DataLinksFormDialog
      v-if="showLinkFormDialog"
      :visible="showLinkFormDialog"
      :leg-id="linkFormProps.legId"
      :event-id="linkFormProps.eventId"
      :event-leg-id="linkFormProps.eventLegId"
      :cruise-id="linkFormProps.cruiseId"
      :experiment-id="linkFormProps.experimentId"
      :simulation-id="linkFormProps.simulationId"
      :event-network-id="linkFormProps.eventNetworkId"
      :link-id="linkFormProps.linkId"
      title="Link"
      @saved="
        showLinkFormDialog = false;
        refresh();
      "
      @close="
        showLinkFormDialog = false;
        refresh();
      "
    >
    </DataLinksFormDialog>
    <DeleteDialog
      v-if="showDeleteDialog"
      :visible="showDeleteDialog"
      @delete-confirmed="onDeleteConfirmed"
      @close="
        showDeleteDialog = false;
        notDeletedAlertText = '';
        refresh();
      "
      title="Delete Link?"
      :warning="notDeletedAlertText"
    >
    </DeleteDialog>
    <EventOverviewDialog
      v-if="showEventOverviewDialog"
      :visible="showEventOverviewDialog"
      :event-id="selectedEventId"
      :leg-id="legId"
      @close="
        showEventOverviewDialog = false;
        refresh()
      "
    >
    </EventOverviewDialog>
    <v-expansion-panels
      v-if="data_links.length"
      v-model="panel"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-icon> {{$icons.mdiFilter}} </v-icon>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              sm="6"
              align="center"
            >
              <v-select
                :items="typeItems"
                v-model="selectedTypes"
                label="Filter Types"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text text-caption"
                  >
                    (+{{ selectedTypes.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-action>
                    <v-icon left>{{ getIcon(item) }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleTypes"
                  >
                    <v-list-item-action>
                      <v-icon :color="selectedTypes.length > 0 ? 'indigo darken-4' : ''">
                        {{ toggleTypesIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    <v-data-table
      :headers="headers"
      :items="data_links"
      :loading="loading"
      item-key="name"
      class="elevation-2"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, -1],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="dataLinksItemsPerPage"
    >
      <template v-slot:top>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-btn
                v-if="postPermission"
                class="elevation-1 plus-button"
                fab
                x-small
                @click="addLink()"
              >
                <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.link_path`]="{ item }">
        <a
          v-if="item.protocol==='http://hdl.handle.net/'"
          v-bind:href="'https://hdl.handle.net/' + item.link_path"
          target="_blank"
        >https://hdl.handle.net/{{ item.link_path }}
        </a>
        <a
          v-else-if="item.protocol==='http://dx.doi.org/'"
          v-bind:href="'https://doi.org/' + item.link_path"
          target="_blank"
        >https://doi.org/{{ item.link_path }}
        </a>
        <a
          v-else-if="item.protocol==='http://'"
          v-bind:href="'http://' + item.link_path"
          target="_blank"
        >http://{{ item.link_path }}
        </a>
        <a
          v-else
          v-bind:href="'https://' + item.link_path"
          target="_blank"
        >https://{{ item.link_path }}
        </a>
      </template>
      <template v-slot:[`item.related_to`]="{ item }">
        <div v-if="item.leg">
          <v-chip class="osis-chip leg-chip">
            Expedition {{ item.leg.name }}
          </v-chip>
        </div>
        <div v-if="item.event">
          <v-chip
            class="osis-chip event-chip"
            @click="showEventDialog(item.event.id)"
          >
            <div>
              Event {{ item.event.label }}
            </div>
          </v-chip>
        </div>
        <div v-if="item.cruise">
          <v-chip class="osis-chip cruise-chip">
            Cruise {{ item.cruise.label }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-tooltip
            top
            v-if="
              editPermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-orange mr-2"
                @click="editLink(item)"
              >
                {{$icons.mdiPencil}}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiPencil}}
          </v-icon>
          <v-tooltip
            top
            v-if="
              deletePermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-red mr-2"
                @click="deleteLink(item)"
              >
                {{$icons.mdiDeleteOutline}}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiDeleteOutline}}
          </v-icon>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiRequests from '@/mixins/apiRequests'
import permissions from '@/mixins/permissions'
import helperFunctions from '@/mixins/helperFunctions'

export default {
  name: 'DataLinks',
  mixins: [apiRequests, permissions, helperFunctions],
  components: {
    DataLinksFormDialog: () => import('@/components/DataLinks/DataLinksFormDialog.vue'),
    DeleteDialog: () => import('@/components/DeleteDialog.vue'),
    EventOverviewDialog: () => import('@/components/Events/EventOverviewDialog.vue'),
  },
  props: ['legId', 'eventId', 'cruiseId', 'experimentId', 'simulationId', 'eventNetworkId'],
  data() {
    return {
      panel: [],
      data_links: [],
      loading: true,
      loadPermissions: true,
      options: {},
      editPermission: false,
      postPermission: false,
      deletePermission: false,
      showLinkFormDialog: false,
      showDeleteDialog: false,
      selectedTypes: [],
      linkFormProps: {
        linkId: null,
        legId: null,
        eventId: null,
        eventLegId: null,
        cruiseId: null,
        simulationId: null,
        eventNetworkId: null
      },
      idToDelete: null,
      typeItems: [
        { text: 'DSHIP', value: "DSHIP" },
        { text: 'Blog', value: "Blog" },
        { text: 'Location', value: "Location" },
        { text: 'Sample Repository', value: "Sample Repository" },
        { text: 'Code Repository', value: "Code Repository" },
        { text: 'Cruise Report', value: "Cruise Report" },
        { text: 'Print Publication', value: "Print Publication" },
        { text: 'URL', value: "URL" },
        { text: 'Open Data Repository', value: "Open Data Repository" },
        { text: 'Internal Data Repository', value: "Internal Data Repository" }
      ],
      showEventOverviewDialog: false,
      selectedEventId: null,
      notDeletedAlertText: '',
    }
  },
  computed: {
    headers() {
      if (this.eventId || this.cruiseId || this.experimentId || this.simulationId || this.eventNetworkId) {
        return [
          {
            text: 'Type of link',
            value: 'link_type',
            filter: this.typeFilter,
          },
          {
            text: 'Link',
            value: 'link_path',
          },
          {
            text: 'Description',
            value: 'description',
          },
          {
            text: 'Date created',
            value: 'date_created',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false
          }
        ]
      } else {
        return [
          {
            text: 'Type of link',
            value: 'link_type',
            filter: this.typeFilter,
          },
          {
            text: 'Link',
            value: 'link_path',
          },
          {
            text: 'Description',
            value: 'description',
          },
          {
            text: 'Date created',
            value: 'date_created',
          },
          {
            text: 'Related to',
            value: 'related_to',
            sortable: false
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false
          }
        ]
      }
    },
    allTypesSelected() {
      return this.selectedTypes.length == this.typeItems.length
    },
    someTypesSelcted() {
      return this.selectedTypes.length > 0 && !this.allTypesSelected
    },
    toggleTypesIcon() {
      if (this.allTypesSelected) return this.$icons.mdiCloseBox;
      if (this.someTypesSelcted) return this.$icons.mdiMinusBox;
      return this.$icons.mdiCheckboxBlankOutline;
    },
    dataLinksItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("dataLinksItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("dataLinksItemsPerPage", value);
      },
    },
  },
  watch: {
    'legId': function (val, oldVal) {
      this.getDataLinks()
      this.options = {}
    },
  },
  async mounted() {
    await this.getDataLinks()
    await this.getPermissions()
    this.selectedTypes = this.typeItems.map(item => item.value)
  },
  methods: {
    relatedToFilter(value) {
      if (!this.list) {
        return true;
      }
      return this.list.includes(value);
    },
    async getDataLinks() {
      this.loading = true
      let requestParams = {}
      if (this.eventId) {
        requestParams = {
          method: "GET",
          url: 'expeditions/' + this.legId + '/events/' + this.eventId + '/data_links'
        }
      } else if (this.cruiseId) {
        requestParams = {
          method: "GET",
          url: 'cruises/' + this.cruiseId + '/data_links'
        }
      } else if (this.experimentId) {
        requestParams = {
          method: "GET",
          url: 'experiments/' + this.experimentId + '/data_links'
        }
      } else if (this.simulationId) {
        requestParams = {
          method: "GET",
          url: 'simulations/' + this.simulationId + '/data_links'
        }
      } else if (this.eventNetworkId) {
        requestParams = {
          method: "GET",
          url: 'event_networks/' + this.eventNetworkId + '/data_links'
        }
      } else {
        requestParams = {
          method: "GET",
          url: 'expeditions/' + this.legId + '/data_links',
          queryParams: {
            include_cruise: true,
            include_events: true
          }
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        this.data_links = await res.json()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async getPermissions() {
      let baseUrl = ''
      let isMainObject = false
      if (this.legId) {
        isMainObject = true
      } else if (this.experimentId) {
        isMainObject = true
      } else if (this.simulationId) {
        isMainObject = true
      } else if (this.cruiseId) {
        baseUrl = '/v1/cruises/' + this.cruiseId + '/data_links'
      } else if (this.eventNetworkId) {
        baseUrl = '/v1/event_networks/' + this.eventNetworkId + '/data_links'
      }

      if (!isMainObject) {
        let permissionsToAsk = {
          'editPermission': { route: baseUrl + '/0', action: 'PATCH' },
          'postPermission': { route: baseUrl, action: 'POST' },
          'deletePermission': { route: baseUrl + '/0', action: 'DELETE' },
        }
        let permissions = await this.getRoutePermission(permissionsToAsk)
        this.editPermission = permissions.editPermission.permission
        this.postPermission = permissions.postPermission.permission
        this.deletePermission = permissions.deletePermission.permission
      } else {
        if (this.legId) {
          let permissionsToAsk = {
            'patchPermission': { leg_id: this.legId, action: 'patch' }
          }
          let permissions = await this.getExpeditionPermissionFromApi(permissionsToAsk)
          this.editPermission = permissions.patchPermission.permission
          this.postPermission = permissions.patchPermission.permission
          this.deletePermission = permissions.patchPermission.permission
        } else if (this.experimentId) {
          let permissionsToAsk = {
            'patchPermission': { experiment_id: this.experimentId, action: 'patch' }
          }
          let permissions = await this.getExperimentPermissionFromApi(permissionsToAsk)
          this.editPermission = permissions.patchPermission.permission
          this.postPermission = permissions.patchPermission.permission
          this.deletePermission = permissions.patchPermission.permission
        } else if (this.simulationId) {
          let permissionsToAsk = {
            'patchPermission': { simulation_id: this.simulationId, action: 'patch' }
          }
          let permissions = await this.getSimulationPermissionFromApi(permissionsToAsk)
          this.editPermission = permissions.patchPermission.permission
          this.postPermission = permissions.patchPermission.permission
          this.deletePermission = permissions.patchPermission.permission
        }
      }

    },
    async onDeleteConfirmed() {
      let url = 'data_links/' + this.idToDelete

      const requestParams = {
        method: "DELETE",
        url: url,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 204) {
          this.showDeleteDialog = false
          await this.refresh()
        }
        else {
          const data = await res.json()
          this.notSavedAlertText = this.extractFirstErrorMessage(data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    editLink(link) {
      Object.keys(this.linkFormProps).forEach((i) => this.linkFormProps[i] = null)
      this.linkFormProps.linkId = link.id
      if (link.leg) {
        this.linkFormProps.legId = link.leg.id
      } else if (link.cruise) {
        this.linkFormProps.cruiseId = link.cruise.id
      } else if (link.experiment) {
        this.linkFormProps.experimentId = link.experiment.id
      } else if (link.simulation) {
        this.linkFormProps.simulationId = link.simulation.id
      } else if (link.event_network) {
        this.linkFormProps.eventNetworkId = link.event_network.network_id
      } else if (link.event) {
        this.linkFormProps.eventId = link.event.id
        this.linkFormProps.eventLegId = link.event.leg_id
      }
      this.showLinkFormDialog = true
    },
    addLink() {
      Object.keys(this.linkFormProps).forEach((i) => this.linkFormProps[i] = null)
      if (this.legId && !this.eventId) {
        this.linkFormProps.legId = this.legId
      } else if (this.cruiseId) {
        this.linkFormProps.cruiseId = this.cruiseId
      } else if (this.legId && this.eventId) {
        this.linkFormProps.eventId = this.eventId
        this.linkFormProps.eventLegId = this.legId
      } else if (this.experimentId) {
        this.linkFormProps.experimentId = this.experimentId
      } else if (this.simulationId) {
        this.linkFormProps.simulationId = this.simulationId
      } else if (this.eventNetworkId) {
        this.linkFormProps.eventNetworkId = this.eventNetworkId
      }
      this.showLinkFormDialog = true
    },
    deleteLink(link) {
      this.idToDelete = link.id
      this.showDeleteDialog = true
    },
    async refresh() {
      await this.getDataLinks()
      this.$emit('links-refreshed')
    },
    typeFilter(value) {
      return this.selectedTypes.includes(value)
    },
    showEventDialog(event_id) {
      this.selectedEventId = event_id
      this.showEventOverviewDialog = true
    },
    getIcon(item) {
      return this.selectedTypes.includes(item.value) ? this.$icons.mdiCheckboxMarked : this.$icons.mdiCheckboxBlankOutline;
    },
    toggleTypes() {
      this.$nextTick(() => {
        if (this.allTypesSelected) {
          this.selectedTypes = []
        } else {
          this.selectedTypes = this.typeItems.map(item => item.value)
        }
      })
    },
  }
}
</script>

<style>
</style>