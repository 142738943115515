<template>
  <div class="mx-14 mt-6">
    <SuccessAlert
      v-if="successAlert"
      @close="successAlert = false"
    >
      Email reminder sent
    </SuccessAlert>
    <DeliverableFormDialog
      v-if="showDeliverableFormDialog"
      :visible="showDeliverableFormDialog"
      :leg-id="selectedLegId"
      :deliverable-id="selectedDeliverableId"
      @saved="
        showDeliverableFormDialog = false;
        refresh();
      "
      @close="
        showDeliverableFormDialog = false;
        refresh();
      "
    >
    </DeliverableFormDialog>
    <DeleteDialog
      v-if="showDeleteDialog"
      :visible="showDeleteDialog"
      @delete-confirmed="onDeleteConfirmed"
      @close="
        showDeleteDialog = false;
        notDeletedAlertText = '';
        refresh();
      "
      title="Delete Deliverable?"
      :warning="notDeletedAlertText"
    >
    </DeleteDialog>
    <LegOverviewDialog
      ref="legOverviewDialog"
      v-if="showLegDialog"
      :visible="showLegDialog"
      :leg-id="selectedLegId"
      :preferred-tab=4
      @close="
        showLegDialog = false
        refresh()
      "
    >
    </LegOverviewDialog>
    <DeliverableOverviewDialog
      v-if="showDeliverableDialog"
      :visible="showDeliverableDialog"
      :deliverable-id="selectedDeliverableId"
      :leg-id="selectedDeliverableLegId"
      @close="
        showDeliverableDialog = false;
        refresh();
      "
    >
    </DeliverableOverviewDialog>
    <h4 class="display-1 mb-4">All Deliverables</h4>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col sm="2">
        <Autocomplete
          v-if="getPersonPermission"
          v-model="selectedPersonId"
          label="Responsible Person"
          url="persons/short"
          item-text="fullname"
          item-value="id"
          :server-search=true
          :min-search-length=2
          hint="Type name to search"
          @input="filterInput"
        ></Autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        sm="2"
        class="text-right"
      >
        <v-btn
          class="osis-button"
          @click="sendEmailReminder"
        >
          Send email reminder
          <v-icon
            class="osis-blue"
            right
          > {{$icons.mdiEmailOutline}} </v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="deliverables"
      :options.sync="options"
      :server-items-length="totalDeliverables"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, -1],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="totalDeliverablesItemsPerPage"
      :key="dataTableForceRefresh"
      @click:row="showDeliverable"
      @update:sort-by="sortTable"
      @update:sort-desc="sortTable"
    >
      <template v-slot:[`item.leg`]="{ item }">
        <div v-if="item.leg">
          <v-chip
            class="osis-chip leg-chip"
            @click.stop="showLeg(item.leg.id)"
          >
            {{ item.leg.name }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.flag`]="{ item }">
        <div :class="flagColours[item.id]">{{ item.flag }}</div>
      </template>
      <template v-slot:[`item.due`]="{ item }">
        <div v-if="item.flag!='PUBLISHED'">
          {{ item.due.trim().split(/\s+/)[0] }}
        </div>
      </template>
      <template v-slot:[`item.file`]="{ item }">
        <div v-if="item.file">
          {{ item.file.name }}
          <div v-if="item.file.description">({{ item.file.description }})</div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-tooltip
            top
            v-if="
              editPermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-orange mr-2"
                @click.stop="editDeliverable(item)"
              >
                {{$icons.mdiPencil}}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiPencil}}
          </v-icon>
          <v-tooltip
            top
            v-if="
              deletePermission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-red mr-2"
                @click.stop="deleteDeliverable(item)"
              >
                {{$icons.mdiDeleteOutline}}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiDeleteOutline}}
          </v-icon>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'
import helperFunctions from '@/mixins/helperFunctions'

export default {
  name: 'TotalDeliverables',
  components: {
    DeliverableFormDialog: () => import('@/components/Deliverables/DeliverableFormDialog.vue'),
    DeliverableOverviewDialog: () => import('@/components/Deliverables/DeliverableOverviewDialog.vue'),
    LegOverviewDialog: () => import('@/components/Legs/LegOverviewDialog.vue'),
    Autocomplete: () => import('@/components/Basics/Autocomplete.vue'),
    DeliverableFormDialog: () => import('@/components/Deliverables/DeliverableFormDialog.vue'),
    DeleteDialog: () => import('@/components/DeleteDialog.vue'),
    SuccessAlert: () => import('@/components/SuccessAlert.vue'),
  },
  mixins: [permission, apiRequests, helperFunctions],
  data() {
    return {
      nameSearch: '',
      nameAwaitingSearch: false,
      selectedPersonId: null,
      totalDeliverables: 0,
      deliverables: [],
      abortController: null,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Expedition',
          value: 'leg',
          sortable: false
        },
        {
          text: 'Device',
          value: 'gear',
          sortable: false
        },
        {
          text: 'Parameter',
          value: 'parameter'
        },
        {
          text: 'resp. Person',
          value: 'pic',
          sortable: false
        },
        {
          text: 'Flag',
          value: 'flag'
        },
        {
          text: 'Due Date',
          value: 'due'
        },
        {
          text: 'File',
          value: 'file',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      flagColours: {},
      dataTableForceRefresh: 0,
      selectedLegId: null,
      showLegDialog: false,
      selectedDeliverableId: null,
      selectedDeliverableLegId: null,
      showDeliverableDialog: false,
      showDeliverableFormDialog: false,
      showDeleteDialog: false,
      notDeletedAlertText: '',
      getPersonPermission: false,
      editPermission: false,
      deletePermission: false,
      successAlert: false,
    }
  },
  watch: {
    nameSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    'options.page': {
      async handler() {
        await this.getDataFromApi()
        this.getFlagColours()
        this.dataTableForceRefresh += 1
      }
    },
    'options.itemsPerPage': {
      async handler() {
        await this.getDataFromApi()
        this.getFlagColours()
        this.dataTableForceRefresh += 1
      }
    },
  },
  async beforeMount() {
    await this.getPermissions()
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.name = this.nameSearch }
      if (this.selectedPersonId) { filter.pic_id = this.selectedPersonId }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    totalDeliverablesItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("totalDeliverablesItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("totalDeliverablesItemsPerPage", value);
      },
    },
  },
  methods: {
    async refresh() {
      await this.getDataFromApi()
      this.getFlagColours()
    },
    async sortTable() {
      await this.getDataFromApi()
      this.getFlagColours()
      this.dataTableForceRefresh += 1
    },
    showLeg(legId) {
      this.selectedLegId = legId
      this.showLegDialog = true
    },
    showDeliverable(deliverable) {
      this.selectedDeliverableId = deliverable.id
      this.selectedDeliverableLegId = deliverable.leg_id
      this.showDeliverableDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getDeliverables()
        if (data) {
          this.deliverables = data.data
          this.totalDeliverables = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getDeliverables() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'deliverables',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async sendEmailReminder() {
      const requestParams = {
        method: "PUT",
        url: 'deliverables/email_reminder'
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          this.successAlert = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    getFlagColours() {
      const dateNow = new Date()
      for (const element of this.deliverables) {
        const dateDue = new Date(element.due)
        if (element.flag === 'OPEN') {
          if (dateDue > dateNow) {
            this.flagColours[element.id] = 'black--text'
          } else {
            this.flagColours[element.id] = 'red--text'
          }
        }
        if (element.flag === 'OSIS') {
          if (dateDue > dateNow) {
            this.flagColours[element.id] = 'black--text'
          } else {
            this.flagColours[element.id] = 'orange--text'
          }
        }
        if (element.flag === 'PUBLISHED') {
          this.flagColours[element.id] = 'green--text'
        }
      }
    },
    async getPermissions() {
      let permissionsToAsk = {
        'getPersonPermission': { route: '/v1/persons/short', action: 'GET' },
        'editPermission': { route: '/v1/expeditions/' + this.legId + '/deliverables/0', action: 'PATCH' },
        'deletePermission': { route: '/v1/expeditions/' + this.legId + '/deliverables/0', action: 'DELETE' },
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.getPersonPermission = permissions.getPersonPermission.permission
      this.editPermission = permissions.editPermission.permission
      this.deletePermission = permissions.deletePermission.permission
    },
    async queryResponsiblePerson(filter) {
      this.responsiblePerson.loading = true
      this.responsiblePerson.items = await this.getPersonsNamesFilter(filter)
      this.responsiblePerson.loading = false
    },
    async getPersonsNamesFilter(filter) {
      const requestParams = {
        method: "GET",
        url: 'persons/short',
        queryParams: {
          filter: '{"lastname":"' + filter + '"}'
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        const data = await res.json()
        return data
      } catch (error) {
        console.log(error)
      }
    },
    filterInput() {
      this.options.page = 1
      this.getDataFromApi()
    },
    editDeliverable(deliverable) {
      this.selectedDeliverableId = deliverable.id
      this.selectedLegId = deliverable.leg_id
      this.showDeliverableFormDialog = true
    },
    deleteDeliverable(deliverable) {
      this.idToDelete = deliverable.id
      this.selectedLegeId = deliverable.leg_id
      this.showDeleteDialog = true
    },
    async onDeleteConfirmed() {
      let url = 'expeditions/' + this.selectedLegeId + '/deliverables/' + this.idToDelete

      const requestParams = {
        method: "DELETE",
        url: url,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 204) {
          this.showDeleteDialog = false
          await this.refresh()
        }
        else {
          const data = await res.json()
          this.notSavedAlertText = this.extractFirstErrorMessage(data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style>
</style>